import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

// Define language strings
const languageStrings = {
  Arabic: {
    selectBanks: "اختر البنوك",
    noBanksAvailable: "لا توجد بنوك متاحة",
    account: "الحساب",
  },
  English: {
    selectBanks: "Select banks",
    noBanksAvailable: "No banks available",
    account: "Account",
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 300,
      border: '1px solid #2CE2D5',
      padding: '12px',
      borderRadius: '10px',
    },
    menuPaper: {
      backgroundColor: '#395D6B',
    },
    select: {
      width: '100% !important',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      color: '#2CE2D5 !important',
    },
    bankName: {
      fontWeight: 'bold',
      color: '#FFFFFF', // Make the bank name white
    },
    expandIcon: {
      color: '#FFFFFF', // Make the expand/collapse icons white
    },
    avatar: {
      marginRight: theme.spacing(1),
    },
  }),
);

// Type definitions
interface Account {
  id: number;
  bank_account_id: number;
  account_number: string;
  image: string;
  type: string;
}

interface Bank {
  name: string;
  bank_id: number;
  accounts: Account[];
}

interface ParentChildDropdownProps {
  bankData: Bank[];
  handleApiCall: (selectedBankIds: number[]) => void;
}

export default function ParentChildDropdown({ bankData, handleApiCall }: ParentChildDropdownProps) {
  const classes = useStyles();
  const [expandedBank, setExpandedBank] = useState<number | null>(null); // Stores only one expanded bank ID
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>(() => {
    const savedData = localStorage.getItem('bank_account_ids');
    return savedData ? JSON.parse(savedData) : [];
  });
  const [language, setLanguage] = useState<'Arabic' | 'English'>('Arabic'); // Default language is Arabic

  // Deduplicate banks by bank_id to avoid issues
  const uniqueBanks = Array.from(new Map(bankData.map((bank) => [bank.bank_id, bank])).values());

  useEffect(() => {
    localStorage.setItem('bank_account_ids', JSON.stringify(selectedAccounts));
    apiCallWithSelectedIds(selectedAccounts);
  }, [selectedAccounts]);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'Arabic' || selectedLanguage === 'English') {
      setLanguage(selectedLanguage as 'Arabic' | 'English');
    }
  }, []);

  // Function to call the API after selecting
  const apiCallWithSelectedIds = (ids: number[]) => {
    handleApiCall(ids);
  };

  const handleParentToggle = (bankId: number, accountIds: number[]) => {
    const newSelectedAccounts = selectedAccounts.some((id) => accountIds.includes(id))
      ? selectedAccounts.filter((id) => !accountIds.includes(id))
      : [...selectedAccounts, ...accountIds];

    setSelectedAccounts(newSelectedAccounts);
  };

  const handleChildToggle = (accountId: number) => {
    const newSelectedAccounts = selectedAccounts.includes(accountId)
      ? selectedAccounts.filter((id) => id !== accountId)
      : [...selectedAccounts, accountId];

    setSelectedAccounts(newSelectedAccounts);
    apiCallWithSelectedIds(newSelectedAccounts);
  };

  const isAllAccountsSelected = (accountIds: number[]) => {
    return accountIds.every((id) => selectedAccounts.includes(id));
  };

  const handleExpandCollapse = (bankId: number) => {
    // Toggle the expanded state: Expand the clicked one and close others
    setExpandedBank((prev) => (prev === bankId ? null : bankId));
  };

  // Get selected items as a string for tooltip display
  const getSelectedItemsString = (): string => {
    const selectedItems = selectedAccounts.map((id) => {
      const bank = uniqueBanks.find((b) =>
        b.accounts.some((account) => account.bank_account_id === id)
      );
      const account = bank?.accounts.find(
        (account) => account.bank_account_id === id
      );
      return account ? account.account_number : '';
    });

    return selectedItems.join(', ');
  };

  // Get translated text for the current language
  const t = (key: keyof typeof languageStrings['Arabic']) => {
    return languageStrings[language][key];
  };

    // Check if banks array is empty
    const isEmpty = bankData.length === 0;

  return (
    <div>
      <FormControl className={classes.formControl} disabled={isEmpty}>
      {isEmpty ? (
        // Display message when no banks are available
        <Typography style={{ color: "#2CE2D5" }}>
          {t('noBanksAvailable')}
        </Typography>
      ) : (
        <Select
          multiple
          title={selectedAccounts.length > 0 ? getSelectedItemsString() : ''}
          displayEmpty
          value={selectedAccounts}
          input={<Input />}
          renderValue={(selected) => {
            if ((selected as number[]).length === 0) {
              return t('selectBanks'); // Use language-dependent placeholder
            }

            const selectedItems = (selected as number[]).map((id) => {
              const bank = uniqueBanks.find((b) =>
                b.accounts.some((account) => account.bank_account_id === id)
              );
              const account = bank?.accounts.find(
                (account) => account.bank_account_id === id
              );
              return account ? account.account_number : '';
            });

            return selectedItems.join(', '); // Join selected items by comma
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 300,
              },
              className: classes.menuPaper,
            },
          }}
          classes={{ select: classes.select, icon: classes.icon }}
        >
          {uniqueBanks.map((bank) => {
            const accountIds = bank.accounts.map((account) => account.bank_account_id);
            const firstAccountImage = bank.accounts[0]?.image;

            return (
              <div key={bank.bank_id}>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent toggle on checkbox click
                    handleExpandCollapse(bank.bank_id);
                  }}
                >
                  <Checkbox
                    checked={isAllAccountsSelected(accountIds)}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from collapsing when clicking checkbox
                      handleParentToggle(bank.bank_id, accountIds);
                    }}
                    indeterminate={
                      selectedAccounts.some((id) => accountIds.includes(id)) &&
                      !isAllAccountsSelected(accountIds)
                    }
                  />
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Box display="flex" alignItems="center">
                      {firstAccountImage && (
                        <Avatar src={firstAccountImage} className={classes.avatar} />
                      )}
                      <ListItemText primary={bank.name} className={classes.bankName} />
                    </Box>
                    {expandedBank === bank.bank_id ? (
                      <ExpandLessIcon className={classes.expandIcon} />
                    ) : (
                      <ExpandMoreIcon className={classes.expandIcon} />
                    )}
                  </Box>
                </MenuItem>
                {expandedBank === bank.bank_id &&
                  bank.accounts.map((account) => (
                    <MenuItem
                      key={`${bank.bank_id}-${account.bank_account_id}`}
                      onClick={() => handleChildToggle(account.bank_account_id)}
                      style={{ paddingLeft: 32 }}
                    >
                      <Checkbox
                        checked={selectedAccounts.includes(account.bank_account_id)}
                      />
                      <Box display="flex" alignItems="center">
                        <Avatar src={account.image} className={classes.avatar} />
                        <ListItemText primary={`${account.account_number}`} className={classes.bankName} />
                      </Box>
                    </MenuItem>
                  ))}
              </div>
            );
          })}
        </Select>
      )}
      </FormControl>
    </div>
  );
}
