import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { openIcon, groupArrow } from "./assets"
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import EngagementRequestwebController, {
    Props
} from "./EngagementRequestController.web";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
import { convertISOTo12HourFormat } from "../../../components/src/commonFn";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class ModifyBooking extends EngagementRequestwebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    cancelOrReschedule = () => {
        return (
            <Modal
                open={!!this.state.isCancelCallModalOpen || !!this.state.isRescheduleCallModalOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                data-test-id="cancel-or-reschedule"
            >

                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

                    <Box style={{
                        height: "33%",
                        margin: "20px",
                        color: "#FFFFFF",
                        background: "#3D3751",
                        padding: "35px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: 'center',
                        borderRadius: "12px"

                    }}>
                        <Typography variant="h6" style={{ width: '75%', fontWeight: 600, textAlign: 'center' }}>
                            {this.state.isCancelCallModalOpen ?
                                `${this.getStringTransactions('cancelCall')}`
                                :
                                `${this.getStringTransactions('reScheduleCall')}`
                            }
                        </Typography>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: '92%',
                                marginTop: '1.9rem'
                            }}
                        >
                            <Button
                                data-test-id="cancereschedule"
                                onClick={() => this.handleCancelOnModal()}
                                style={{
                                    color: "red",
                                    borderRadius: "8px",
                                    border: "1px solid red",
                                    width: "38%",
                                    margin: "5px",
                                    fontWeight: 600,
                                    textTransform: 'unset'
                                }}
                            >{this.getStringTransactions('canc')}</Button>
                            <Button
                                data-test-id="handleCancelOrReschedule"
                                onClick={() => this.handleCancelOrReschedule()}
                                style={{
                                    color: "C9F9F6",
                                    borderRadius: "8px",
                                    border: "1px solid #C9F9F6",
                                    width: "38%",
                                    margin: "5px",
                                    fontWeight: 600,
                                    textTransform: 'unset'
                                }}
                            >{this.getStringTransactions('Yes')}</Button>


                        </Box>
                    </Box>
                </Box>

            </Modal >
        )
    }

    showDataNone = () => this.state.show ? '' : 'none';
    showMoreData = (value: string) => this.state.show === value ? `${this.getStringTransactions('Less')}` :  this.getStringTransactions('More');
    showNameData = () => {
        return (
            <>
                {this.state.showName ? this.state.statusWithPlannerData?.wealth_planner.data.attributes.email : this.maskEmail()}&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.showName ? (
                    < VisibilityOutlinedIcon data-test-id="handleIconClick1New" style={webStyle.handleClickMargin1} onClick={(event) => this.handleIconClick(event)} />
                ) : (
                    <VisibilityOffOutlinedIcon style={webStyle.handleClickMargin1} onClick={(event) => this.handleIconClick(event)} data-test-id="handleIconClick2New" />
                )}
            </>
        )
    }

    isUpIcon = () => {
        return this.state.statusWithPlannerData?.wealth_planner.data.attributes.total_reviews ?
            <IconButton data-test-id="handleClickNewData" onClick={(event) => { this.handleClick(event) }}>
                {this.state.isUp ? <ExpandLessRoundedIcon style={webStyle.expandMoreRounded1} /> : <ExpandMoreRoundedIcon style={webStyle.expandMoreRounded1} />}
            </IconButton> :
            null
    }
    isUpData = () => this.state.isUp === false ? "" : "none";

    average_rating_Data_Value = () => {
        return this.state.statusWithPlannerData?.wealth_planner.data.attributes.average_rating ?
            <>
                <Typography style={webStyle.average_rating_fontSize1} className="">{this.state.statusWithPlannerData?.wealth_planner.data.attributes.average_rating}</Typography>&nbsp;&nbsp;
                <Box style={webStyle.fontSizeText1} className="">
                    <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor1} />} value={this.state.statusWithPlannerData?.wealth_planner.data.attributes.average_rating} readOnly precision={0.5} size="small" />
                </Box>
            </> :
            null
    }

    total_reviews_reviews = () => {
        return this.state.statusWithPlannerData?.wealth_planner.data.attributes.total_reviews ? `${this.getStringTransactions('basedOut')} ${this.state.statusWithPlannerData?.wealth_planner.data.attributes.total_reviews} ${this.getStringTransactions('rev')}` : this.getStringTransactions('noReviews')
    }
    acceptedRequest1 = () => {
        return this.state.EngagmentReject.status === "accepted" && this.state.permission === null ? (
            <Box style={webStyle.succsecrequest1}>
               {this.getStringTransactions('AllowReq')}
            </Box>
        ) : null;
    };
    rejectedRequest1 = () => {
        return this.state.EngagmentReject.status === "rejected" ? (
            <Box style={webStyle.rejectedrequest1}>
                {this.getStringTransactions('RejReq')}
            </Box>
        ) : null;
    };
    handleIconClick1 = () => {
        this.setState(prevState => ({ isIconClicked: !prevState.isIconClicked }));

    }

    renderPendingStatus = (EngagementRequest: any) => {
        if (EngagementRequest.status === 'pending') {
            return (
                <Box style={webStyle.minBoxDisplay12}>
                    <NextButton data-test-id="sendEngagementButton" disabled>{this.getStringTransactions('ReqSent')}</NextButton>
                </Box>
            )
        }
    }
    renderProfile = (userImage: string, userName: string, imgSize:string, fontSize?:string) => {
        return userImage ?
            <img src={userImage} width={imgSize} height={imgSize} />
            :
            <div style={{...webStyle.noImg as React.CSSProperties, fontSize: fontSize? fontSize: "32px", height:imgSize, width: imgSize  }}>
                {getFirstCharacterFromEachWord(userName)}
            </div>
    }
// Customizable Area End


render() {
    // Customizable Area Start
    const { statusWithPlannerData, EngagementRequest, dataActive, } = this.state;

    return (
        <>
            <BackgroundBoxSatyle dir={this.state.direction}>
                <CnvestmentBox>
                    <Box>
                        <Box style={webStyle.profileNameNewIcon}>
                            <Box style={webStyle.profileNameNeww}>
                                <Box style={webStyle.planning} data-test-id="ModifyBox">{this.getStringTransactions('modifyTitle')}</Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={webStyle.webStyleMenBox1} className="webStyleMenBox">
                                <Box style={webStyle.planningBackground1}>
                                    <Box style={webStyle.minBoxDisplay}>
                                        <Box style={webStyle.emailLable1}>
                                            {this.renderProfile(statusWithPlannerData?.wealth_planner.data.attributes.image as string,statusWithPlannerData?.wealth_planner.data.attributes.name as string, "94px" )}
                                            <Box style={webStyle.boxMargin1}>
                                                <TextLable>{statusWithPlannerData?.wealth_planner.data.attributes.name}</TextLable>
                                                <Box className="" style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('nameCorp')}</Box>
                                                <Box className="textlabel_NameOfCorporation" style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('Location')}:&nbsp;<Textlabel_LosAngeles>{statusWithPlannerData?.wealth_planner.data.attributes.location}</Textlabel_LosAngeles></Box>
                                                <Box style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('JurisdictionAd')}:&nbsp;<Textlabel_LosAngeles>{statusWithPlannerData?.wealth_planner.data.attributes.jurisdiction_advice}</Textlabel_LosAngeles></Box>
                                                <Box style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('Quali')}:&nbsp;<Textlabel_LosAngeles>{statusWithPlannerData?.wealth_planner.data.attributes.qualification}</Textlabel_LosAngeles></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ ...webStyle.boxMargin1, transform: 'translateY(1.5rem)', margin: '0px 25px' }}>
                                            <Box style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('Exp')}:&nbsp;
                                                <Textlabel_LosAngeles>
                                                    {`${(EngagementRequest.attributes?.experience || 0)} Yr${(EngagementRequest.attributes?.experience || 0) > 1 ? 's' : ''}`}
                                                </Textlabel_LosAngeles>

                                            </Box>
                                            <Box className="" style={webStyle.profileNameNeww}>
                                                <Box style={{ fontSize: '1.2rem', color: 'white' }} className="">{statusWithPlannerData?.wealth_planner.data.attributes.average_rating}</Box>&nbsp;&nbsp;
                                                <Box style={{ fontSize: '1.2rem', color: 'white' }} className="">
                                                    {statusWithPlannerData?.wealth_planner.data.attributes.average_rating ? <Rating name="size-small" style={{ fontSize: '1.3rem' }} value={this.state.statusWithPlannerData?.wealth_planner.data.attributes.average_rating} readOnly precision={0.5} size="small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor1} />} /> : null}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    {statusWithPlannerData &&
                                        <Box data-test-id='plannerDataBox'>
                                            <Box className="focusLable" style={webStyle.focusLable1}>{this.getStringTransactions('Focus')}</Box>
                                            <MinBoxDisplayNew style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="minBoxDisplayNew">
                                                <div>
                                                    {statusWithPlannerData?.wealth_planner.data.attributes.focus.map((valueButton: any, index: number) => {
                                                        return (
                                                            <ButtonStyle style={index === 0 ? { marginLeft: '0px' } : {}} key={index} className="nameButton">{valueButton.name}</ButtonStyle>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                <Box style={{ ...webStyle.textlabel_LosAngeles1, marginRight:'25px' }} data-test-id="showMoreNew" onClick={(event) => { this.showMore(this.state.show === statusWithPlannerData?.wealth_planner.data.id ? '' : statusWithPlannerData?.wealth_planner.data.id, event) }}>{this.showMoreData(statusWithPlannerData?.wealth_planner.data.id)}</Box>
                                            </MinBoxDisplayNew>
                                        </Box>
                                    }
                                </Box>
                                <Box style={{ display: this.showDataNone() }}>
                                    <Box style={webStyle.personalDetails1}>
                                        <table>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('Email')}:</td>
                                                <td style={webStyle.tdAnswers1}>
                                                    <Box style={webStyle.minBoxDisplay}>
                                                        {this.showNameData()}
                                                    </Box>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('Certification')}:</td>
                                                <StdQuestions1 className="certificate">{this.state.statusWithPlannerData?.wealth_planner.data.attributes.certificate}</StdQuestions1>
                                            </tr>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('Quali')}:</td>
                                                <StdQuestions1 className="qualification">{this.state.statusWithPlannerData?.wealth_planner.data.attributes.qualification}</StdQuestions1>
                                            </tr>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('Firm')}:</td>
                                                <StdQuestions1 ><div style={webStyle.emailLable1}><span>{this.state.statusWithPlannerData?.wealth_planner.data.attributes.url} &nbsp;</span><span>
                                                    <a href={this.state.statusWithPlannerData?.wealth_planner.data.attributes.url} target="_blank" data-test-id="stopPropagationNew" onClick={(event) => event.stopPropagation()} > <img src={openIcon} alt="" style={{ width: '17px', borderRadius: '0px' }} /></a></span></div></StdQuestions1>
                                            </tr>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('EmpHist')}:</td>
                                                <StdQuestions1 className="employment_history">{this.state.statusWithPlannerData?.wealth_planner.data.attributes.employment_history}</StdQuestions1>
                                            </tr>
                                            <tr>
                                                <td style={webStyle.tdQuestions1}>{this.getStringTransactions('DisclosureTitle')}:</td>
                                                <StdQuestions1 className="disclosure">{this.state.statusWithPlannerData?.wealth_planner.data.attributes.disclosure}</StdQuestions1>
                                            </tr>
                                        </table>
                                    </Box>
                                    <Divider style={webStyle.dividerLine1} />
                                    <Box style={webStyle.personalDetails1}>
                                        <Box style={webStyle.profileNameNeww}>
                                            <Box style={webStyle.focusLableNew1}>{this.getStringTransactions('RatRew')}:</Box>
                                            <Box className="isUpIcon">
                                                {this.isUpIcon()}
                                            </Box>
                                        </Box>
                                        <Box className="">
                                            <Box className="profileNameNew" style={webStyle.profileNameNeww}>
                                                {this.average_rating_Data_Value()}
                                            </Box>
                                        </Box>

                                        <Box style={webStyle.textlabel_NameOfCorporation1}>{this.total_reviews_reviews()}</Box>
                                        <Box style={{ display: this.isUpData() }}>
                                            <Box className="reviews-container" style={webStyle.marginTopBox1}>
                                                {statusWithPlannerData?.wealth_planner.data.attributes.rating_and_reviews?.slice(0, 2).map((ratingData, index: number) => {
                                                    return (
                                                        <>
                                                            <Box data-test-id="details" style={webStyle.displayBox1} id="details">
                                                                <Box data-test-id="profile-name" style={webStyle.profileName1} id="profileName">
                                                                    {this.renderProfile(ratingData.user_photo, ratingData.user_name, "50px", "24px")}
                                                                    <Typography data-test-id="reviewerName" style={webStyle.reviewerName1}>{ratingData.user_name}</Typography>
                                                                </Box>
                                                                <Box id="dateRating" style={webStyle.profileName1} >
                                                                    <Typography style={webStyle.reviewerNameText1} >{ratingData.date}</Typography>
                                                                    <Box style={webStyle.fontSizeText1} id="reviewStarFordetailsReviews">
                                                                        <Rating value={ratingData.rating} name="size-small" emptyIcon={<StarIcon style={webStyle.stareColor1} fontSize="inherit" />} readOnly precision={0.5} size="small" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box>
                                                                <Typography className="consecteturText" style={webStyle.consecteturText1} data-test-id="main-review-content">{ratingData.description}</Typography>
                                                            </Box>
                                                        </>
                                                    )
                                                })}
                                                <Box className="all-review-btn" data-test-id="dialogCashModelNewData" onClick={(event) => { this.dialogCashModelNew(event) }} style={webStyle.marginTopBox1}>
                                                    <span style={webStyle.allReviewBtn1}>{this.getStringTransactions('AllRev')}</span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {
                            this.renderPendingStatus(EngagementRequest)
                        }
                        {
                            this.state.EngagmentReject.status === "rejected" && <Box style={webStyle.minBoxDisplay12} >
                                <ChooseANewFinancialPlannerButton data-test-id="choose-new-planner" onClick={() => this.goToFinancialPlanner()} >{this.getStringTransactions('DiffPlanner')}</ChooseANewFinancialPlannerButton>
                            </Box>
                        }
                        <Box  >
                            <Box style={webStyle.profileNameNew1}>
                                <Box style={webStyle.planning}>{this.getStringTransactions('ScheduleAppoint')}</Box>
                            </Box>
                            <Box>
                                <Box style={{ ...webStyle.profileNameNew1, flexDirection: 'column', alignItems: 'start' }}>
                                    <Box style={webStyle.planning1}>{statusWithPlannerData?.wealth_planner.data.attributes?.slot}</Box>
                                    <Box style={{ ...webStyle.planning1, marginTop: '1rem' }}>{convertISOTo12HourFormat(statusWithPlannerData?.wealth_planner.data.attributes?.start_time)} - {convertISOTo12HourFormat(statusWithPlannerData?.wealth_planner.data.attributes?.end_time)}</Box>
                                </Box>
                                <Box style={webStyle.profileNameNew1}>

                                </Box>
                            </Box>
                        </Box>

                        <Box  >
                            <Box style={webStyle.profileNameNew12}>
                                <Box style={webStyle.planning}>{this.getStringTransactions('yourPack')}</Box>
                            </Box>

                            <Box>
                                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '12px', justifyContent: 'space-between', width: '50.5%' }}>
                                    <Box style={webStyle.planning1}>{dataActive?.attributes?.name}
                                        <ErrorOutlineOutlinedIcon style={{ fontSize: '35px', color: '#2DE2D5', marginLeft: '4px', cursor: 'pointer' }} data-test-id="btnIconClick" onClick={this.handleIconClick1} />

                                    </Box>
                                    <Typography data-test-id="planAmount" style={{ fontSize: '22px', fontFamily: 'Poppins', fontWeight: 500, color: 'rgba(255, 255, 255, 0.26)' }}>{`${dataActive?.attributes?.convertedCurr} ${dataActive?.attributes?.convertedAmt}`}</Typography>                 

                                </Box>
                                {this.state.isIconClicked && (
                                    <Box style={webStyle.normalCardBox as React.CSSProperties} >
                                        <Box style={{ width: 15, height: 15, position: 'absolute', top: '-8px', left: 50, background: 'rgb(45, 226, 213)', rotate: '45deg' }}></Box>
                                        <Box style={webStyle.planning123}>{this.getStringTransactions('BenifitsOfPlan')}</Box>

                                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                            {dataActive?.attributes?.benefits.map((benefit: string, index: number) => (
                                                <Box key={index} style={webStyle.profileNameNeww}>
                                                    <Box data-test-id="backButton" style={{paddingTop:'8px'}}>
                                                        <img src={groupArrow} style={{...webStyle.marginImg1,width:'54px', height: '24px' }} alt="arrow" />
                                                    </Box>
                                                    <Box style={{ ...webStyle.planning12, wordBreak: 'break-word' }}>
                                                        {benefit}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 80, marginBottom:'1rem' }}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '17px',
                                maxWidth: '400px'
                            }}>
                                {dataActive?.attributes?.name === 'Basic Plan' &&
                                    <Box data-test-id="handle-cancel" style={{ ...webStyle.chatBtn, textAlign: 'center' }} id="ongoing-btns-booking" onClick={this.handleCancel}>{this.getStringTransactions('canc')}</Box>
                                }

                                <Box data-test-id="handle-reschedule" style={{ ...webStyle.chatBtn, textAlign: 'center', backgroundColor: this.state.hover ? '#C9F9F6' : '', color: this.state.hover ? 'black' : '#C9F9F6' }} id="ongoing-btns-booking"
                                    onMouseEnter={() => {
                                        this.setState({ hover: true });
                                    }}
                                    onMouseLeave={() => {
                                        this.setState({ hover: false });
                                    }}
                                    onClick={this.handleReschedule}>{this.getStringTransactions('reSchedule')}</Box>

                            </Box>
                        </Box>


                        <Box>
                            <Dialog
                                open={this.state.isPopupOpenNew}
                                keepMounted
                                data-test-id="dialogModel"
                                onClose={this.dialogCashModelNew}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                                PaperProps={{ style: this.dialogCashPaperStyleNew }}
                            >
                                <DialogContent style={webStyle.openModel1}>
                                    <Box className="">
                                        <Box className="" style={webStyle.profileNameNeww}>
                                            <Box style={webStyle.average_rating_fontSize1} className="">{statusWithPlannerData?.wealth_planner.data.attributes.average_rating}</Box>&nbsp;&nbsp;
                                            <Box style={webStyle.fontSizeText1} className="">
                                                {statusWithPlannerData?.wealth_planner.data.attributes.average_rating && <Rating name="size-medium" size="small" value={statusWithPlannerData?.wealth_planner.data.attributes.average_rating} emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor1} />} readOnly precision={0.5} />}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.textlabel_NameOfCorporation1}>{this.getStringTransactions('basedOut')}{statusWithPlannerData?.wealth_planner.data.attributes.total_reviews} {this.getStringTransactions('rev')}</Box>
                                    <Box className="" style={webStyle.marginTopBox1}>
                                        {statusWithPlannerData?.wealth_planner.data.attributes.rating_and_reviews.map((ratingDataModel: any, index: number) => {
                                            return (
                                                <>
                                                    <Box key={index} className="details" style={webStyle.displayBox1} id="details">
                                                        <Box data-test-id="profile-name" style={webStyle.profileName1} id="profileName">
                                                            <img style={webStyle.reviewerDP} alt="" data-test-id="reviewerDP" src={ratingDataModel.user_photo} />
                                                            <Typography data-test-id="reviewerName" style={webStyle.reviewerName1} >{ratingDataModel.user_name}</Typography>
                                                        </Box>
                                                        <Box id="dateRating" style={webStyle.profileName1} className="date-rating">
                                                            <Typography style={webStyle.reviewerNameText1} data-test-id="date-of-review" >{ratingDataModel.date}</Typography>
                                                            <Box data-test-id="reviewStarFordetailsReviews" style={webStyle.fontSizeText1}>
                                                                <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor1} />} value={ratingDataModel.rating} readOnly precision={0.5} size="small" />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography style={webStyle.consecteturText1} data-test-id="main-review-content">{ratingDataModel.description}</Typography>
                                                    </Box>
                                                </>
                                            )
                                        })}
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Box>

                    </Box>
                </CnvestmentBox>
            </BackgroundBoxSatyle >
            {this.cancelOrReschedule()}
        </>
    );
    // Customizable Area End

}
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    handleClickMargin1: { margin: '0px 15px' },
    openModel1: { padding: '20px 25px' },
    dividerLine1: { color: '#607d8b' },
    expandMoreRounded1: { color: '#3cd7c6' },
    average_rating_fontSize1: { color: '#fff', fontSize: '12px' },
    displayBox1: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    fontSizeText1: { fontSize: '12px', color: 'white' },
    emailLable1: { display: 'flex' },
    clicked1: { boxShadow: "#1bf5e3 0px 0px 10px", border: "1px solid #1bf5e3", borderRadius: "12px", background: "#395d6b", marginBottom: '30px' },
    stareColor1: { color: '#ceccc2' },
    minBoxDisplay12: { display: 'flex', justifyContent: 'center' },
    consecteturText1: { color: '#fff', fontSize: '14px', fontWeight: 400, margin: '20px 0px' },
    marginTopBox1: { marginTop: '20px' },
    textlabel_LosAngeles1: { color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer" },
    textlabel_NameOfCorporation1: { color: "#928F98", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, display: "flex" },
    focusLableNew1: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600 },
    focusLable1: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600, margin: "10px 0px" },
    tdAnswers1: { color: '#2CE2D5', fontSize: '14px', fontWeight: 600, paddingLeft: '15px' },
    allReviewBtn1: { borderRadius: '6px', border: '1px solid #C9F9F6', padding: '5px 20px', color: "#C9F9F6", cursor: 'pointer' },
    personalDetails1: { padding: '10px' },
    tdQuestions1: { color: '#fff', fontSize: '14px', fontWeight: 400 },
    reviewerName1: { color: '#fff', fontWeight: 400 },
    boxMargin1: { margin: '0px 25px' },
    planningBackground1: { borderRadius: "12px", border: "1px solid #57575D", background: "#3C3C51", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", padding: "10px 10px 25px 10px" },
    reviewerNameText1: { color: '#fff', opacity: '0.5', fontWeight: 500 },
    profileName1: { display: 'flex', alignItems: 'center', gap: '10px' },
    webStyleMenBox1: { marginTop: "2rem", borderRadius: "12px", border: "1px solid #57575D", background: "#395d6b", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", marginBottom: '30px' },
    profileNameNeww: { display: 'flex' },
    profileNameNew1: { display: 'flex', alignItems: 'center', marginBottom: '12px' },
    profileNameNew12: { display: 'flex', alignItems: 'center', marginBottom: '12px', marginTop: '32px' },
    profileNameNewIcon: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    reviewerDP: { borderRadius: '50px', width: '50px', height: '50px' },
    minBoxDisplay: { display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" },
    boxMarginEnd: { margin: '0px 25px', display: "flex", justifyContent: "end", width: "100%" },
    listLine: { color: "#94929f", margin: "25px 0px" },
    planning1: { fontSize: "25px", fontWeight: 480, color: "#ffffff42", display: 'flex' },
    planning12: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
    planning123: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 460, marginBottom: '12px' },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" },
    succsecrequest1: { backgroundColor: "#c9e6b0", margin: "25px 0px", color: '#2fc163', borderRadius: "10px", padding: '10px' },
    rejectedrequest1: { backgroundColor: "#f4beba", margin: "25px 0px", color: '#d9545f', borderRadius: "10px", padding: '10px' },
    ScheduleCallBtn: { marginLeft: '15px', color: '#3C3C51', border: '1px solid #C9F9F6', background: '#C9F9F6', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
    chatBtn: { color: '#C9F9F6', border: '1px solid #C9F9F6', padding: '9px 70px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },

    normalCardBox: {
        cursor: 'pointer',
        borderRadius: '12px',
        padding: '24px 27px',
        position: 'relative',
        marginTop: '15px',
        marginLeft: '95px',
        backgroundColor: 'rgb(45, 226, 213)',
        width: 'max-content',
        maxWidth: '350px',
        minWidth: 300
    },
    noImg: {
        width: "94px",
        display: "flex",
        borderRadius: "50%",
        color: "#fff",
        fontWeight: 600,
        backgroundColor: "2CE2D5",
        fontSize:"32px",
        alignItems: "center",
        justifyContent: 'center',
        height: "94px",
        textTransform: "uppercase"
      },
    marginImg1: { marginRight: '10px', cursor: "pointer" },

};
const ButtonStyle = styled(Button)({
    borderRadius: "15px", background: "#395D6B", color: "#FFF", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, padding: "5px 20px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
        backgroundColor: "#395D6B",
    },
})
const MinBoxDisplayNew = styled(Box)({
    display: 'flex', alignItems: "flex-end", flexWrap: "wrap",
})
const BackgroundBoxSatyle = styled(Box)({ backgroundColor: '#2b2a37', width: '100%', height: '100vh', overflowY: 'scroll', })
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})
const NextButton = styled(Button)({
    marginBottom: "10px", textTransform: "capitalize", fontWeight: 600, background: "#7b9398",
    '&:hover': { background: "#7b9398", marginBottom: "10px", textTransform: "capitalize", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "35%", },

})
const ChooseANewFinancialPlannerButton = styled(Button)({
    color: "#2b2a37", marginBottom: "10px", textTransform: "unset", fontWeight: 600, background: "#fff",
    '&:hover': { background: "#fff", marginBottom: "10px", textTransform: "unset", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "25%", },

})
const Textlabel_LosAngeles = styled(Box)({ fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", color: "#2DE2D5", fontWeight: 500, cursor: "pointer", textTransform: "capitalize" })
const StdQuestions1 = styled(Box)({
    fontSize: '14px', color: '#fff', fontWeight: 400, paddingLeft: '15px', textTransform: "capitalize",
})
const TextLable = styled(Box)({ fontFamily: "Poppins", fontSize: "20px", fontStyle: "normal", color: "#FFF", fontWeight: 600, textTransform: "capitalize" })

// Customizable Area End