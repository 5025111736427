import React, { Fragment } from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { subarrowicon, settingicon, down, up } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import { backIcon } from "../../forgot-password/src/assets";
import { filterIcon, deleteIcon ,image_BackIcon } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import VisualAnalyticsController, {
  Props,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.allTransactions();
    addEventListener('beforeunload', function () {
      localStorage.removeItem('type');
      localStorage.removeItem('category');
      localStorage.removeItem('date');
    });


    // Customizable Area End
  }

  // Customizable Area Start
  addstyleCss = () => {
    return this.state.logoImg1AddCash === "rtl" ? this.state.dataValue : this.state.dataValueNew
  }
  image_BackIcon = () => {
    return this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon
  }
  Dropmenu = () => {
    return this.state.logoImg1AddCash === "rtl"?'drop-menu drop-menu-data':'drop-menu drop-menu-arabic'
  }
  arabiccss = () => {
    return this.state.logoImg1AddCash === "rtl" ? "saveMonthlyBudget saveMonthlyBudgetNew" : "saveMonthlyBudget"
  }
  dataArabic = () => {
    return this.state.logoImg1AddCash === "ltr" ? this.getStringVisualAnalytics("Delete") : this.getStringVisualAnalytics("arabicTargetDate")
  }
  TransactionArabic = () => {
    return this.state.logoImg1AddCash === "rtl" ? null : this.getStringVisualAnalytics("Transaction")
  }
  buttonArabic = () => {
    return this.state.logoImg1AddCash === "rtl" ? "rtl" : "ltr" 
  }
  renderNoTransactionData = () => {
    return <>
      {
         this.state.filterTransactionsData.length<1 && <p style={webStyle.notAvailable}>
          No data available
        </p>
      }</>
  }
   formatNumberWithCommas=(value:any)=> {
    if (typeof value === 'number') {
      return value.toLocaleString();
    } else if (typeof value === 'string') {
      let numValue = parseFloat(value.replace(/,/g, ''));
      if (!isNaN(numValue)) {
        return numValue.toLocaleString();
      }
    }
    return value;
  };  
  
  initValFN = (condition: boolean, truthyVal: string | undefined, falsyVal: string | undefined) => {
    const value:any = condition ? truthyVal : falsyVal;
    return !isNaN(value) && value !== undefined ? this.formatNumberWithCommas(value) : value;
  }
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="pagedashboard-wrapper"  id="scrollableDiv" dir={this.state.logoImg1AddCash} style={webStyle.scrollBarStyle}>
        <Loader loading={this.state.loader} />
        <div className="header-wrapper" onClick={this.handleGlobaldropdown}>
          <div className="page-title">
            <h4>{this.state.transactionDetail ? <img src={this.image_BackIcon()} alt="back-icon" data-test-id="transactionDetailBackBtn" className="pointer-show" onClick={() => { this.setState({ transactionDetail: false }) }} /> :
              <NavLink to="/VisualAnalytics"><img src={this.image_BackIcon()} alt="back-icon" /></NavLink>}
              &nbsp; {this.getStringVisualAnalytics("Transactions")}</h4>
          </div>
          <div className="page-info" id="page-info-transaction">
            <ul className="drop-menu" data-test-id="drop-menu">
              <li className="currency-dropdown">
                <div
                  data-test-id="currencyDropdoen"
                  onClick={() => this.handleDropdown()}
                  className={this.state.dropdownVisiblity} >
                  <p data-test-id="fakedatatestid" dir="ltr">
                    {this.state.selectedCurrency}
                  </p>
                  <img src={subarrowicon} alt="subarrow" />
                  <ul>
                    {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                      return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)} key={index}>{value.symbol + value.currency_type}</li>
                    })}

                  </ul>
                </div>
              </li>
              <li className="nav-link-setting">
                <NavLink className="fake-class" to="/Account-Detail">
                  <img src={settingicon} alt="setting" id="setting-img" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-wrapper" onClick={this.handleCategoryDropDown}>
          {this.state.transactionDetail ?
            <div className="screen-wrapper-transaction" data-test-id="detail_card">
              <div className="screen-flex-transaction">
                <div className="recent-flex">
                  <h5 style={{ display: "block", width: "auto" }}> <img src={this.state.singleTransactionDetails.brand_logo ?? this.state.singleTransactionDetails.sub_category.image} className="transactionDetailImg" /></h5>
                  <img src={deleteIcon} data-test-id="onCancel" className="pointer-show" onClick={() => { this.onCancel() }} />
                </div>
                <div className="detail-view-transaction">
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("Status")}</span>
                    <span className="detail-status-value">{this.state.singleTransactionDetails.status}</span>
                  </div>
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("Category")}</span>
                    <span className="detail-status-value pointer-show" data-test-id="categoryDropdown" onClick={() => this.handleDropdown2()}>{this.state.updatedSubCategoryForSingleTransacion}&nbsp;<img src={subarrowicon} alt="subarrow" />

                    </span>
                  </div>
                  <div className="page-info" id="page-info-transaction">
                    <ul className={this.Dropmenu()} style={{listStyleType:'none'}}  data-test-id="drop-menu">
                      <li className="currency-dropdown">
                        <div
                          data-test-id="currencyDropdoen"
                          className={this.state.dropdownVisiblity2} >
                          <ul>
                            {this.state.categoriesList && this.state.categoriesList.map((value: any, index: number) => {
                              return <li data-test-id={`categoryDataId-${index}`} onClick={() => this.changeValueofSubCategoryOfTransation(value.attributes)}>{value.attributes.name}</li>
                            })}

                          </ul>
                        </div>
                      </li>

                    </ul>
                  </div>
                  <div>
                    <span className="detail-status">{this.getStringVisualAnalytics("Source")}</span>
                    <span className="detail-status-value">{this.state.singleTransactionDetails.source}</span>
                  </div>
                </div>
                <br />
                <div className="detail-view-transaction">
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("CurrentAccount")}</span>
                    <span className="detail-status-value">{this.state.singleTransactionDetails.current_account}</span>
                  </div>
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("Amount")}</span>
                    <span className="detail-status-value red-color" dir="ltr">{this.state.singleTransactionDetails.amount}</span>
                  </div>

                </div>
                <br />
                <div className="detail-view-transaction">
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("Date")}</span>
                    <span className="detail-status-value">{this.state.singleTransactionDetailsDate}</span>
                  </div>
                  <div style={this.state.newCss}>
                    <span className="detail-status">{this.getStringVisualAnalytics("Time")}</span>
                    <span dir="ltr" className="detail-status-value">{this.state.singleTransactionDetails.time}</span>
                  </div>

                </div>
                <div className={this.arabiccss()}>
                <span onClick={() => { this.updateSubCategoryOfTransaction() }} className="save-btn-transDetails" data-test-id="save-btn-filter">{this.getStringVisualAnalytics("Save")}</span>
              </div>
              </div>
              
            </div>
            :
            <div className="screen-wrapper-transaction" >
              <div className="screen-flex-transaction">
                <div className="recent-flex">
                  {this.getMonth()}
                  <NavLink to="/Filters">
                    <img src={filterIcon} />
                  </NavLink>
                </div>
                <div className="recent-wrapper">
                {this.renderNoTransactionData()}
                {
                  this.state.filterTransactionsData.length > 0 && 
                  <InfiniteScroll
                  dataLength={this.state.filterTransactionsData.length}
                  next={this.allTransactions}
                  hasMore={true}
                  loader={<span />}
                  scrollableTarget="scrollableDiv"
                >
                {this.state.filterTransactionsData && this.state.filterTransactionsData.map((value: {
                  attributes: {
                    id: number,
                    sub_category: {
                      image:string,
                      name:string
                    },
                    converted_amount:string,
                    description: string,
                    user_currency:string
                    amount_operand:string,
                    brand_logo: string,
                    transaction_date:string
                  }
                }, index: number) => {
                  return (
                    <Fragment key={index}>
                      <div className="recent-block-content">
                        <div className="recent-item" style={{ display: "block" }}>
                          <img src={value.attributes.brand_logo ?? value.attributes.sub_category.image} className="spending-image" alt="SpendingImage" />
                          <div className="recent-inner-item">
                            <h4 data-test-id={`transaction-fn-${index}`} onClick={() => { this.transactionDetailAPI(value.attributes.id) }} className="pointer-show">{this.initValFN(value.attributes.description == null ,'Unknown' ,value.attributes.description)}</h4>
                            <p>{value.attributes.transaction_date}</p>
                          </div>
                        </div>
                        <div className="recent-price">
                          <span dir="ltr" style={this.addstyleCss()} className={this.ManageOprandClass(value.attributes.amount_operand)}>
                            {( value.attributes.converted_amount != '0' ?  value.attributes.amount_operand : '') + value.attributes.user_currency}
                            {this.initValFN(value.attributes.converted_amount !== "", this.formatNumberWithCommas(value.attributes.converted_amount), "0")}
                            <img className={this.ManageOprandClass2(value.attributes.amount_operand)} src={this.initValFN(value.attributes.amount_operand === '+', up, down)} alt="downarrow" />
                          </span>
                          <p>{this.initValFN(value.attributes.sub_category.name === 'Other', 'Unknown', value.attributes.sub_category.name)}</p>
                        </div>

                      </div>
                    </Fragment>
                  )
                })}
                </InfiniteScroll>
                }              

                </div>
              </div>
            </div>
          }
        </div>
        <Dialog
          open={this.state.deleteModel}
          keepMounted
          data-test-id="dialogModel"
          onClose={this.dialogModel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.state.dialogCss }}
        >
          <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0px 65px', }}>
            <span style={{
              color: '#fff',
              textAlign: 'center',
              fontSize: '13px',
              fontFamily: 'Poppins',
              fontWeight: 600,
            }}>{this.dataArabic()}</span><br /><span style={{
              color: '#fff',
              textAlign: 'center',
              fontSize: '13px',
              fontFamily: 'Poppins',
              fontWeight: 600,
              display: "flex",
              justifyContent: "center"
            }}>{this.TransactionArabic()}</span></DialogTitle> 
          <DialogContent style={{
            padding: '0px!important',
            marginBottom: '0px',
            paddingTop: '15px'
          }}>
            <DialogContentText id="alert-dialog-slide-description"
              style={{
                color: 'rgb(255, 255, 255) !important',
                fontSize: '20px !important',
                fontFamily: 'Poppins !important',
                fontWeight: 500,
                marginBottom: '0px',
              }}>
              <div className="modal-button-container" dir={this.buttonArabic()}>
                <div className="modal-button modal-save" data-test-id='popup-save-click' onClick={() => this.onCancel()}>
                  {this.getStringVisualAnalytics("Cancel")}
                </div>
                <div className="modal-button modal-discard" onClick={() => this.deleteTransaction()} data-test-id='popup-discard-click' >
                  {this.getStringVisualAnalytics("DeleteNew")}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    notAvailable: {
        color : '#fff',
        fontFamily: 'Poppins',
    },
    scrollBarStyle :{ height: "100vh", overflow: "auto", width: "100%"}

}
// Customizable Area End
