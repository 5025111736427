import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import { modelSuccess } from "../../multipageforms2/src/assets";
import { subarrowicon, settingicon,calendarImg } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {image_BackIcon} from "./assets";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Select from 'react-select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ErrorMessage, Form, Formik } from "formik";
import { Box, OutlinedInput } from "@material-ui/core";


let theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});

theme = createTheme(theme, {
    overrides: {
       
        MuiOutlinedInput: {
            root: {
                
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                },
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
              
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiSvgIcon: {
            root: {
                color: '#94989c',
            },
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayheader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#33abb6",
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"

            },
        },
       
    },
});
import MultipleSelectInputFiled from "../../../components/src/SeleteBox/MultipleSelectInputFiled";
// Customizable Area End

import GoalmanagementController, {
    Props,
} from "./GoalmanagementController";

export default class VisualAnalytics extends GoalmanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    arabiccss = () => {
        return this.state.logoImg1AddCash === "rtl" ? "saveMonthlyBudget saveMonthlyBudgetNew" : "saveMonthlyBudget"
    }
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
            <div className="pagedashboard-wrapper" dir={this.state.logoImg1AddCash}>
                <div className="header-wrapper">
                    <div className="page-title">
                        <h4><NavLink to="/Goalmanagement"><img src={this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon} alt="back-icon" /></NavLink> &nbsp; {this.getStringGoalmanagement("SpendingGoals")}</h4>
                    </div>
                    <div className="addMonthlyGoal page-info" id="addOtherGoal">
                        <ul data-test-id="addOtherGoal" className="drop-menu" >
                            <li className="otherGoalAdd">
                                <div
                                    data-test-id="currencyDropdoen"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity} >
                                    <p data-test-id="otherGoalPageCurrency" dir="ltr">
                                        {this.state.selectedCurrency} 
                                    </p>
                                    <img src={subarrowicon} alt="subarrow" />
                                    <ul id="fakeIdForOtherGoalPage">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={`currency-${index}`} onClick={() => this.selectCurrencyGoal(value)}>{value.symbol + value.currency_type}</li>
                                        })}

                                    </ul>
                                </div>
                            </li>
                            <li>
                                <NavLink className="otherGoalNavLink" to="/Account-Detail">
                                    <img src={settingicon} alt="setting" id="setting-img" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                    <div className="content-wrapper addMonthlyPageWrapper ">
                        <Formik
                            enableReinitialize
                            initialValues={this.state.otherGoalData}
                            onSubmit={(values) => {
                                this.setOtherGoals(values)
                             }}
                            data-test-id="addOtherGoalForm"
                            validationSchema={this.validationSchemaOtherGoal}
                        >
                            {({ handleBlur, values, handleSubmit, handleChange, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit} data-test-id='submitForm' translate={"en"}>
                                    <div className="screen-wrapper-demo">
                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("SaveFor")}</p>
                                            <OutlinedInput
                                                style={webStyle.inputRoot}
                                                data-test-id="goalName"
                                                value={values.goal_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={"Name"}
                                                fullWidth={true}
                                                name="goal_name"
                                                type="text"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="goal_name" />
                                            </Box>
                                        </div>
                                        <div className="fieldDiv fieldDiv2">
                                            <div className="fieldInnerDiv inputFieldStyle">
                                                <p className="labelText">{this.getStringGoalmanagement("Amount")}</p>
                                                <OutlinedInput
                                                    style={webStyle.inputRoot}
                                                    data-test-id="metalName"
                                                    value={values.amount}
                                                    onChange={handleChange}
                                                    placeholder={"Enter amount"}
                                                    fullWidth={true}
                                                    name="amount"
                                                    type="number"
                                                    onBlur={handleBlur}
                                                />
                                                <Box sx={webStyle.fieldError}>
                                                    <ErrorMessage name="amount" />
                                                </Box>
                                            </div>
                                            <div className="fieldInnerDiv myne-goals inputFieldStyle">
                                                <p className="labelText">{this.getStringGoalmanagement("Currency")}</p>
                                                <Select
                                                    styles={{
                                                        control: (defaultStyles) => ({
                                                            ...defaultStyles,
                                                            backgroundColor: "#2a2a38",
                                                            borderRadius: '8px',
                                                            padding: "8px",
                                                            color: 'rgba(255, 255, 255, 0.60)',
                                                            fontSize: '15px',
                                                            fontFamily: 'Poppins',
                                                            textAlign: "left",
                                                        }),
                                                    }}
                                                    classNamePrefix="an-simple-select"
                                                    options={this.state.currencyListDropDownForm}
                                                    onChange={(event) => event && setFieldValue("currency", event)}
                                                    className="selectMenu selectedMenuDropdown"
                                                    data-test-id="selectCurrencyMenu"
                                                    value={values.currency}
                                                    placeholder="Select Currency"
                                                />
                                                <Box sx={webStyle.fieldError}>
                                                    <ErrorMessage name="currency" />
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="fieldDiv inputFieldStyle" >
                                            <p className="labelText">{this.getStringGoalmanagement("StartDate")}</p>

                                            <Box data-test-id="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        error={false}
                                                        placeholder="DD/MM/YYYY"
                                                        variant="inline"
                                                        fullWidth
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        name="startDate"
                                                        value={values.start_date}
                                                        onChange={(values) => setFieldValue("start_date", values, true)}
                                                        autoOk
                                                        InputProps={{
                                                            style: {
                                                                padding: '10px 0px',
                                                                borderRadius: '8px',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        KeyboardButtonProps={{
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        data-test-id="startDate"
                                                        helperText=""
                                                        className="uploadBox newBoxContant12Pension"
                                                        keyboardIcon={<img src={calendarImg} style={{
                                                            borderRadius: "0",
                                                            height: "18px",
                                                            width: "18px",
                                                            marginTop: "-4px"
                                                        }} />}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="start_date" />
                                            </Box>

                                        </div>

                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("TargetDate")}</p>
                                            <Box data-test-id="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        error={false}
                                                        name="target_date"
                                                        placeholder="DD/MM/YYYY"
                                                        variant="inline"
                                                        fullWidth
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        value={values.target_date}
                                                        onChange={(values) => setFieldValue("target_date", values, true)}
                                                        autoOk
                                                        data-test-id="targetDate"
                                                        helperText=""
                                                        InputProps={{
                                                            style: {
                                                                padding: '10px 0px',
                                                                borderRadius: '8px',
                                                                color: 'white'
                                                            }
                                                        }}
                                                        KeyboardButtonProps={{
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        className="uploadBox newBoxContant12Pension"
                                                        keyboardIcon={<img src={calendarImg} style={{
                                                            borderRadius: "0",
                                                            height: "18px",
                                                            width: "18px",
                                                            marginTop: "-4px"
                                                        }} />}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="target_date" />
                                            </Box>
                                        </div>

                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("AssignCategory")}</p>
                                            <Select
                                                styles={{
                                                    control: (defaultStyles) => ({
                                                        ...defaultStyles,
                                                        borderRadius: '8px',
                                                        padding: "8px",
                                                        color: 'rgba(255, 255, 255, 0.60)',
                                                        fontSize: '16px',
                                                        fontFamily: 'Poppins',
                                                        textAlign: "left",
                                                        backgroundColor: "#2a2a38",
                                                    }),
                                                }}
                                                classNamePrefix="an-simple-select"
                                                data-test-id="selectCategoryMenu"
                                                value={values.category}
                                                options={this.state.categoryListDropDownForm}
                                                onChange={(event) => event && setFieldValue("category", event)}
                                                className="selectMenu selectedMenuDropdown"
                                                placeholder="Select"
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="category" />
                                            </Box>
                                        </div>
                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("selectYourBanks")}</p>
                                            <MultipleSelectInputFiled
                                                banks={this.state.bankOptions}
                                                selectedBanks={values.selectedBanks} 
                                                setFieldValue={setFieldValue}
                                            />
                                        </div>
                                    </div>
                                    <div className={this.arabiccss()}>
                                        <span onClick={() => handleSubmit()} className="save-btn-filter-other-goal right-align" data-test-id="formSubmit">{this.getStringGoalmanagement("CreateButton")}</span>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                <div className="confirmation-popup-dialog">
                <Dialog
                    id="other-goal-confirmation-id"
                    open={this.state.openSuccessModel}
                    keepMounted
                    data-test-id="dialogModel"
                    onClose={this.dialogCashModel}
                    aria-labelledby="alert-dialog-slide-title"
                    className="main-dialog"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.dialogCashPaperStyle }}
                >
                    <DialogTitle className="Dialog-title" id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={modelSuccess} /></DialogTitle>
                    <DialogContent className="dialog-content" style={{ padding: '8px 20px' }}>
                        <DialogContentText data-test-id="dialog-content-text" id="alert-dialog-slide-description" className='dialogContent'>
                           {this.getStringGoalmanagement("Successfully")}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                </div>

            </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    inputRoot: {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
    },
    fieldError:{
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
   },
   nextButton:{
        color: '#3C3C50',
        textAlign: 'center',
        fontSize: '17px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        width: "auto",
   },
   fieldStyle:{
    marginTop:"15px"
   }
}

// Customizable Area End
