Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader = "Create an account to continue.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.lable="كلمه المرور"
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.createAccountButton = 'Create Account'
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.emailIsRequired = "Email id is a required field"
exports.pleaseEnterSemPassword = "Enter your confirm password.";
exports.pleaseEnterAValidPassword = "Enter your password.";
exports.pleaseEnteravalidEmailnew = "Email already registered email must be a valid email";
exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint =
  "account/accounts";
exports.apiMethodTypeAddDetail = "POST";
exports.emailSentApiMethod = "POST"
exports.singupApiContentType = "application/json"
exports.submitPdfFormUrl = "/account_block/accounts"
exports.exampleAPiMethod = "POST"
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.LoginsubmitPdfFormUrl = "account_block/accounts"
exports.FocusList="/bx_block_formapprovalworkflow/wealth_planners/focus_list"
exports.apiWorkflowSignup="/bx_block_formapprovalworkflow/wealth_planners"


exports.languageList = {
  English: {
    signUp: 'Sign up as wealth planner',
    signUpBtn: 'Sign up ',
    signUpCustomer: 'Sign up as customer',
    labelHeader: 'Create an account to continue.',
    emailAddress: 'Email Address',
    emailPlaceholder: 'Enter your email address',
    languagePasswordPlaceholder: 'Enter password',
    languagePasswordRequirements: 'Password requirements:',
    confirmPassword: 'Confirm password',
    requirementsPlaceholder: 'Confirm password',
    termCondtions: 'By creating an account you agree to our',
    termCondtionsLink: 'Terms & Condtions.',
    alreadyAccount: 'Already have an account?',
    singInAccount: 'Sign in',
    createAccountButton: 'Create Account',
    or: 'or',
    minimumLength: "Minimum 8 characters in length",
    atLowercase: 'At least one lowercase letter (a-z)',
    oneUppercase: 'At least one uppercase letter (A-Z)',
    oneNumber: 'At least one number (0-9)',
    emailIsRequired: "Email id is a required field.",
    pleaseEnterAValidPassword: "Enter your password.",
    validation:'Your password must meet the requirements',
    pleaseEnterSemPassword : "Your password doesn't match!",
    pleaseEnterConfirmPassword : "Enter your confirm password."
  },
  Arabic: {
    signUp: 'التسجيل',
    labelHeader: 'إنشاء حساب للمتابعة',
    emailAddress: 'عنوان البريد الإلكتروني',
    emailPlaceholder: 'أدخل عنوان بريدك الإلكتروني',
    languagePasswordPlaceholder: 'أدخل كلمة المرور',
    languagePasswordRequirements: 'تأكيد كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    requirementsPlaceholder: 'تأكيد كلمة المرور',
    termCondtions: 'من خلال إنشاء حساب فإنك توافق على ',
    termCondtionsLink: 'شروطنا وأحكامنا ',
    alreadyAccount: 'هل لديك حساب بالفعل؟',
    singInAccount: ' تسجيل الدخول',
    createAccountButton: 'إنشاء حسا',
    or: 'أو',
    minimumLength: "الحد الأدنى 8 أحرف",
    atLowercase: 'حرف صغير واحد على الأقل (a-z)',
    oneUppercase: 'حرف كبير واحد على الأقل (A-Z)',
    oneNumber: 'رقم واحد على الأقل (0-9)',
    emailIsRequired: "البريد الإلكتروني حقل مطلوب",
    pleaseEnterAValidPassword: "ادخل رقمك السري.",
    validation:'يجب أن تفي كلمة المرور الخاصة بك بالمتطلبات',
    pleaseEnterSemPassword :"كلمة المرور الخاصة بك غير متطابقة!",
    pleaseEnterConfirmPassword : "أدخل تأكيد كلمة المرور الخاصة بك."
  }
}
// Customizable Area End
