import React from "react";
// Customizable Area Start
import "./style.css";
import {
    logoNew,
    settingicon,
    subarrowicon
} from "./assets";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import { deleteIcon } from "../../freetrial/src/assets";
import DeleteDialogModal from "../../../components/src/DeleteDialogModal";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import { CircularProgress } from "@material-ui/core";
import { addCommas } from "../../../components/src/common";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.getCryptoData()
        // Customizable Area End
    }
    // Customizable Area Start
    cryptoDataListing = (tickers:{
        "id": number,
        "ticker_name": string,
        "name": string,
        "provider_ticker": string,
        "asset_type": string,
        "currency": string,
        "price": string,
        "old_price": string,
        "logo": string,
        "difference": string,
        "percentage": number,
        "operand": string,
        "symbol": string
    }[]) => {
        return (
            <div className="faq-content" id="stockListing">
            {tickers
                && tickers.map((value) => {
                    return (
                        <>
                            <div className="faq-content-wrapper" id="faqContentWrapper">
                                <img
                                    data-test-id="iconofstock"
                                    src={value.logo? value.logo:logoNew}
                                    alt="bank-account"
                                    width="45"
                                    height="45"
                                />
                                <div
                                    data-test-id="faqcontentforstock"
                                    className="faq-inner-content"
                                >
                                    <p>{value.name}</p>
                                    <span className="stock">
                                        {value
                                            .symbol +
                                            addCommas(value.price)}
                                    </span><br />
                                    <span className={value.operand == '+' ? "plusStock" : "minusStock"}>
                                        {value
                                            .operand +
                                            value.difference + `(${value.percentage}%)`}
                                    </span>
                                </div>
                            </div>
                        </>
                    );
                })
            }

        </div>
        )
    }
    renderDir =()=>{
        return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
      }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className="pagedashboard-wrapper" id="stockDetails" dir={this.renderDir()}>
                <div className="header-wrapper" id="stockDetailsWrapper">
                    <div className="page-title" id="pageTitleForStockDetails">
                        <h4 className="stockTitleAndRedirection">
                            <NavLink to="/dashboard"><img src={backIcon} alt="back-icon" style={{transform: this.state.direction ==='rtl'? 'scaleX(-1)':'' }} /></NavLink> &nbsp;
                            {this.getStringCrypto("CryptoCurrency")} </h4>
                    </div>
                    <div  className="page-info">
                        <ul className="drop-menu" id="dropdownMenu">
                            <li className="stockDetailCurrencyChange">
                                <div
                                    data-test-id="currencyDropdoen"
                                    id="customDiv"
                                    onClick={this.handleDropdown}
                                    className={this.state.dropdownVisiblity}
                                >
                                    <p className="viewdetail1">
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul className="viewdetail1">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                        })}
                                    </ul>
                                    
                                </div>
                            </li>
                            <li id="settingRedirectionFromStockDetails">
                                <NavLink id="navLinkFromStockDetails" to="/Account-Detail">
                                    <img  src={settingicon} alt="setting" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    this.state.loadingCryptoData &&
                    <div style={webStyle.loaderCss}>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh',
                                }}
                            >
                                <CircularProgress style={{ color: "#FFF" }} />
                            </div>
                        </div>
                    </div>
          
                }
                
                {
                    this.state.cryptoData && this.state.cryptoData.length > 0 &&
                    <div className="content-wrapper" id="StockDetailContentWrapper">
                        {
                                this.state.cryptoData.map((cryptoData,key) => {
                                    return (<div className="assets-block" id="StockDetailassetsBlock" key={key}>
                                        <div className="assets-block-content" id="assetsBlockInStockDetails">
                                            <div className="faq-wrapper" data-test-id="faqWrapperInStockDetails">
                                                <div
                                                    className="faq-block active"
                                                    data-test-id="stocksDetails"
                                                    style={{ position: 'relative' }} // Allow positioning of delete icon
                                                >
                                                    <div className="faq-heading faq-heading-3 stocksDetails">
                                                        <div className="faq-inner-heading fih-StocksDetails">
                                                            <img width="45" height="45" src={cryptoData.icon_url ? cryptoData.icon_url : logoNew} alt="bankimage" />
                                                            <div className="faq-inner-heading-content" id="innerContentInStocksDetails">
                                                                <p>{cryptoData.full_name}</p>
                                                                {
                                                                    cryptoData.tickers ? <span>{cryptoData.tickers.length} {cryptoData.tickers.length > 1 ? "Coins" : "Coin"}</span> :
                                                                        <span style={webStyle.lineHeight} className={cryptoData.operand === '+' ? "plusStock" : "minusStock"}>
                                                                            {cryptoData.operand + cryptoData.difference + `(${cryptoData.percentage}%)`}
                                                                        </span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div data-test-id="stockDetailsBalance" className="faq-heading-content faq-heading-content3">
                                                            <div className="inner-info" id="sdInnerInfo">
                                                                <span>{this.getStringCrypto("Balance")}</span>
                                                                <p className="overviewStockSymbol">
                                                                    {cryptoData.symbol + addCommas(cryptoData.price)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        cryptoData.tickers && this.cryptoDataListing(cryptoData.tickers)
                                                    }
                                                    {
                                                        !cryptoData.tickers && <span
                                                            style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                right: '10px',
                                                                cursor: 'pointer',
                                                                fontSize: '20px',
                                                                color: '#FF0000',
                                                            }}
                                                            onClick={() => this.handleOpenDeleteModal(cryptoData.ticker_id, 'crypto')}
                                                        >
                                                            <img src={deleteIcon} width={"18px"} />
                                                        </span>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                               
                            })
                        }    
                </div>
            }

                <DeleteDialogModal
                    open={this.state.openDeleteModel}
                    onClose={this.closeDeleteModal}
                    onConfirm={this.handleDeleteCrypto}
                    dialogTitle={this.getStringCrypto("deleteConfirm")}
                    cancelText={this.getStringCrypto("Cancel")}
                    confirmText={this.getStringCrypto("Delete")}
                />
                
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle= {
    container :{
        display:"flex",
        alignItems:"center",
        marginBottom:"14px"
    },
    lineHeight: {
        lineHeight:"24px"
    },
    loaderCss: {
        transform: 'translateY(-110px)'
    },
}
// Customizable Area End
