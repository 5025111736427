// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import toast from "react-hot-toast";
import storage from "../../../framework/src/StorageProvider";
import { groupArrow, groupArrow11, groupArrowOrange } from "./assets";
import { DayValue } from "react-modern-calendar-datepicker";
import moment from "moment";
import React from "react";
import { getStorageData } from "framework/src/Utilities";

export interface MockSlotsResponse {
'id': number,
'attributes': {
  'id': string,
  'type': string,
  'time_slot': string,
  'availablility': boolean
},
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface AllResponseData {
  services_selected: false
}

export interface CountriesData {
  country: []
}

export interface Address {
  attributes: {
    name: string,
    city: string,
    zipcode: string,
    address: string,
    country: string
  }
}

export interface Response1 {
  package: {
    data: []
  }
  data: []
}

export interface AddressFormDetails {
  name: string,
  city: string,
  zip_code: string,
  address: string,
  country: string
}
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

type Conversation = {
  account_sid: string;
  conversation_sid: string;
  chat_service_sid: string;
  messaging_service_sid: string;
  unique_name: string;
  participant1_account_id: string;
  participant1_sid: string;
  participant2_account_id: string;
  participant2_sid: string;
  state: string;
  date_created: string;
  date_updated: string;
};

type ConversationMessage = {
  message: string;
  conversation: Conversation;
  plannerID: number;
  userName: string;
  userImage: string | null;
};

interface S {
  currentLocation: {
    label: string;
    value: string;
    current_latitude: string;
    current_longitude: string;

  };
  packageList: any;
  checkedBoxes: any,
  paymentMethod: string,
  address: any;
  countryName: any;
  addAddressFormDetails: { name: any, address: any, city: any, zip_code: any, country: any },
  paymentMethodAddDetails: { cardName: string, expiry: any, cardNumber: any, cvv: any },
  selectedPaymentMethod: any;
  logoImg1Login: string,
  languagesLogin: string,
  selectedPlan1: any;
  saveCvvInfo: string;
  saveCardName: string;
  purchasedPlan: number;
  paymentMethods: any;
  openSuccessAddress: boolean,
  openRejectedPopup: boolean;
  slotBooking: boolean;
  card_id: any;
  deleteAlertMessage: boolean;
  manageBtn: boolean;
  deleteErrorMsg: boolean;
  saveCardForFuture: boolean;
  plan_id: any;
  verifyCvvValue: any;
  openDeleteSuccess: boolean,
  apiCallingStage: boolean;
  countries: any,
  allCountriesLocation: any;
  countryPage: any,

  selectedBoxIndex: any,

  destination: {
    destination_latitude: string;
    destination_longitude: string;
  } | null;
  cutomerData: { [keyVal: string]: string | number };
  customerID: string;
  moreVisible: boolean;
  openDeleteModel: boolean;
  deleteSuccessPopup: boolean;
  token: string;
  plannedEvents: IPlannedEvent[];
  show: string,
  isUp: boolean,
  showName: boolean,
  isAllReviewopupOpen: boolean,
  selectedPlannedEvent: IPlannedEvent | null,
  dataActive: any,

  allReviews: IAllReview[]
  selectedDay: string,
  calenderSelectedDay: DayValue,
  date: string,
  timeSlotDate: { date: string, time: string },
  plannerId: string,
  next_date: string,
  next_date_slot:{
    day: number,
    month: number,
    year: number
  },
  slots?: {
    start_time:string,
    end_time:string,
    availability: boolean
  }[]
  activeTab: string,
  selectedPlan: null | number,
  onGoingServices: ISerivce[],
  completedServices: ISerivce[],
  currentPlannerId: number,
  currentPlannerName: string,
  currentPlannerImage: string,
  openAccessDialog: boolean,
  openReviewDialog: boolean,
  reviewRating: number | null,
  reviewText: string,
  openReviewSuccessModal: boolean,
  canReview: boolean,
  timeD: string,
  serviceDate: any,
  postPlan: number,
  getPlan: number,
  currentPlan: IPlan | null
  isServiceSelected: boolean
  availableSlot: any,
  unAvailableSlot: any,
  anchorEl: HTMLAnchorElement | null;
  wealthPlannerName: string;
  wealthPlannerImage:string;
  userName:string;
  toastOpenn:boolean;
  toastOpenedStatus: boolean;
  chatPlanner: {
    plannerName: string,
    plannerId: string | number,
    plannerImg: string
  };
  fromjoinCall:boolean,
  isLoader: boolean,
  BookingTime:string,
  bookingDuration:string,
  planner_id:any,
  direction: string,
  language: string,
}

interface SS { }

export default class FormApprovalWorkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";
  getPlannedEventListCallId: string = "";
  getAllReviewsCallId: string = "";
  postPlanApiCallId: string = "";
  getPlanApiCallId: string = "";
  deletePlannerId: string = ""

  editAddressCallId: string = "";
  addAddressCallId: string = "";
  getAddressCallId: string = "";
  addPaymentMethodCallId: string = "";
  addPaymentMethodFromEditCallId: string = "";
  completePurchaseCallId: string = "";
  getCardsCallId: string = "";
  verifyCvvCallId: string = "";
  deleteCardCallId: string = "";
  getCountryCallId: string = "";


  getCalTime: string = "";
  getPackageListCallId: string = ""
  getOngoingServiceCallId: string = "";
  getCompletedServiceCallId: string = "";
  allowAccessPermissionId: string = "";
  createReviewId: string = "";
  getServiceStatusId: string = "";
  postRescheduleCalenderId: string = "";
  interval2Id: ReturnType<typeof setInterval> | number = 0;
  MeetingApiCallid2:string="";
  GetUserInfoApiCallid:string="";
  getConversationApiId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      currentLocation: {
        label: "",
        value: "",
        current_latitude: "",
        current_longitude: "",
      },
      token: localStorage.getItem('token') as string,
      packageList: [],
      paymentMethod: '',
      addAddressFormDetails: { name: '', address: '', city: '', zip_code: '', country: '' },
      address: '',
      countryName: '',
      paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' },
      saveCvvInfo: '',
      saveCardName: '',
      selectedPaymentMethod: '',
      logoImg1Login: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      languagesLogin: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      selectedPlan1: '',
      purchasedPlan: 0,
      paymentMethods: '',
      timeSlotDate: { date: '', time: '' },
      openSuccessAddress: false,
      openRejectedPopup: false,
      slotBooking: true,
      card_id: '',
      deleteAlertMessage: false,
      manageBtn: false,
      deleteErrorMsg: false,
      saveCardForFuture: false,
      plan_id: '',
      verifyCvvValue: '',
      openDeleteSuccess: false,
      apiCallingStage: true,
      countries: "",
      allCountriesLocation: [],
      countryPage: 0,

      selectedBoxIndex: -1,

      checkedBoxes: [],
      destination: { destination_latitude: "", destination_longitude: "" },
      cutomerData: {},
      customerID: "",
      moreVisible: false,
      openDeleteModel: false,
      deleteSuccessPopup: false,
      dataActive: [],

      plannedEvents: [],
      show: "",
      isUp: true,
      showName: false,
      isAllReviewopupOpen: false,
      selectedPlannedEvent: null,
      allReviews: [],
      selectedDay: this.getCurrentDate(),
      calenderSelectedDay: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, 
        day: new Date().getDate(),
      },
      date: "",
      plannerId: localStorage.getItem('currentPlannerId') as string,
      slots: undefined,
      next_date: "",
      next_date_slot:{
        day: 0,
        month:0,
        year:0
      },
      activeTab: "ongoing",
      selectedPlan: null,
      onGoingServices: [],
      completedServices: [],
      currentPlannerId: 0,
      currentPlannerName: "",
      currentPlannerImage: "",
      openAccessDialog: false,
      openReviewDialog: false,
      reviewRating: 0,
      reviewText: "",
      openReviewSuccessModal: false,
      canReview: false,
      timeD: '',
      serviceDate: {},
      postPlan: 0,
      getPlan: 0,
      currentPlan: null,
      isServiceSelected: false,
      availableSlot: [],
      unAvailableSlot: [],
      anchorEl:null,
      wealthPlannerName:'',
      userName:'',
      toastOpenn:false,
      toastOpenedStatus: false,
      chatPlanner:{
        plannerId: "",
        plannerImg:"",
        plannerName:""
      },
      wealthPlannerImage:'',
      fromjoinCall:false,
      isLoader: false,
      BookingTime:'',
      bookingDuration:'',
      planner_id:'',
      direction: 'ltr',
      language: "English",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const _planner_id = urlParams.get('planner_id');
      if (_planner_id) {
        this.setState({planner_id:_planner_id})
      }
    
    this.getAddress();

    this.getCountriesForAddress();
    this.getPackageList();
    this.loadPlannedEventList();
    this.getCards();
    this.getOngoingServices();
    this.getCompletedServices();
    this.getServiceStatus();
    this.getUserData();
    this.interval2Id = window.setInterval(()=>{this.checkSlots()}, 6000);

    this.state.selectedDay && this.getCalenderDate(this.state.selectedDay)
    const timeSlotDate = localStorage.getItem("timeSlotDate");

    if (timeSlotDate) {
      const slot = JSON.parse(timeSlotDate);
      this.setState({
        timeSlotDate: slot
      })
    }
    let selectLanguage = await getStorageData('language');
    const currentLanguage = selectLanguage === 'Arabic' ? 'rtl' : 'ltr';
    if(!selectLanguage){
    selectLanguage = "English"
    }
    this.setState({ direction: currentLanguage ,language: selectLanguage});

    this.handleGetPlanDetails()

    const { location }: any = this.props;
    this.setState({
      serviceDate: location.state
    })
    this.getCurrentLocation();
  }

  getStringEstatePlanningDashboard = (keyValue:string) => {
    return configJSON.estatePlanningDashboardTranslations[this.state.language][keyValue];
  };

  callCountry = async () => {
    if (this.state.apiCallingStage) {
      this.setState({ apiCallingStage: false }, () => {
        this.getCountriesForAddress();
      })
    }
  }
 
  getUserData=()=>{
    const headers = {
        token: localStorage.getItem("authToken"),
       
    };
    const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetUserInfoApiCallid = requestMsg.messageId;
    
    requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/profiles/show`
    );
    requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
}
convertToUTC(slotTime:any) {
  const localDate = new Date(slotTime);
  const utcOffset = localDate.getTimezoneOffset() * 60000; // Get the time zone offset in milliseconds
  const utcDate = new Date(localDate.getTime() + utcOffset);
  const hours = String(utcDate.getUTCHours()).padStart(2, '0');
  const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');
  
  return `${hours}:${minutes}`;
}
formatTimeFromISO(isoString:string) {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

handleToast() {
  if (!this.state.toastOpenedStatus) {
    this.setState({toastOpenn:true, toastOpenedStatus: true})
  }
}

  checkSlots=()=>{
    
    const currentTime = new Date();
 const updatedPlanners = this.state.plannedEvents.map((planner) => {
  if (planner.attributes.slot){
 
      const [day, month] = planner.attributes.slot.split(' ');
      const formattedStartTime = this.formatTimeFromISO(planner.attributes.slot_time);

      const [hours, minutes] = formattedStartTime.split(':');
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const monthIndex = monthNames.indexOf(month);
      const bookingDateTime = new Date(Date.UTC(currentTime.getUTCFullYear(), monthIndex,  parseInt(day, 10),  parseInt(hours, 10),
      parseInt(minutes, 10)));
      const currentUTCTime = new Date(Date.UTC(
          currentTime.getUTCFullYear(),
          currentTime.getUTCMonth(),
          currentTime.getUTCDate(),
          currentTime.getUTCHours(),
          currentTime.getUTCMinutes(),
          currentTime.getUTCSeconds()
      ));
      const differenceInMillis:any = (bookingDateTime instanceof Date)
      ? bookingDateTime.getTime() - currentUTCTime.getTime() 
      : 1;

      const differenceInMinutes= Math.floor(differenceInMillis / (1000 * 60));

      if (differenceInMinutes <= 5 && differenceInMinutes >= -planner?.attributes?.plan?.duration) {
       this.handleToast()
          return { ...planner, iscallenable:true ,istimeexceeded:false,toastOpenn: true};
      } else {
if (differenceInMinutes<=-planner?.attributes?.plan?.duration){
  return { ...planner, iscallenable:false,istimeexceeded:true,};

}
if (differenceInMinutes >= 5){
  return { ...planner, iscallenable:false,istimeexceeded:false, };

}
      }
    }
    return (planner)
    });
    this.setState({plannedEvents:updatedPlanners})
  }

  checkRejectedStatus =(status: string)=>{
    return status === 'rejected';
  }
  checkCall = (plannedEvent: IPlannedEvent)=>  
  !plannedEvent.attributes.slot || plannedEvent.istimeexceeded;

  checkTime =(plannedEvent: IPlannedEvent)=>{
   
   return plannedEvent.attributes.slot?!plannedEvent.istimeexceeded:false

  }
  
  handleToastClose=()=>{
    this.setState({toastOpenn:false})
  }
  getShow=(id:string)=>{
    if (this.state.show === id ){
      return( "" )
    }
    else{
      return id
    }
  }
  getReviewMessage(plannedEvent:any) {
    return plannedEvent.attributes.total_reviews
      ? `${this.getStringTransactions('basedOut')} ${plannedEvent.attributes.total_reviews} ${this.getStringTransactions('reviews')}`
      : this.getStringTransactions('NoReviews');
  }
  getResult(plannedEvent:any){
   return plannedEvent.iscallenable?plannedEvent.iscallenable:false
  }
  getValue=(plannedEvent:any)=>{
    return this.checkRejectedStatus(plannedEvent.attributes.request_status) ? 0.6 :1
    }
  getButtonText=(iscallenable:boolean)=>{
   return(iscallenable?this.getStringTransactions('JoinCall'):this.getStringTransactions('VideoCall')) 
  }
  getExperienceLabel(experience:number) {
    return experience > 1 ? "Yrs" : "Yr";
  }
  gotoVideo=(plannedEvent:any)=>{
    if (plannedEvent.iscallenable) {
      const BookingTime=this.formatTimeFromISO(plannedEvent.attributes.slot_time)
     this.setState({wealthPlannerName:plannedEvent.attributes.name,wealthPlannerImage:plannedEvent.attributes.image,currentPlannerId:plannedEvent.attributes.planner_id,
      BookingTime:BookingTime,
      bookingDuration:plannedEvent.attributes.plan.duration
    })
        const headers2 = {
          token: localStorage.getItem("authToken"),
        };
        
        const requestMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.MeetingApiCallid2 = requestMsg.messageId;
        const AccountID2 =plannedEvent.attributes.planner_id;
        requestMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_appointment_management/appointments/get_video_call_details?for=customer&planner_id=${AccountID2}`
        );
        requestMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers2)
        );
        requestMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
         
    }
}

  getCurrentDate() {

    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");
    this.handleNavigationMessage(message)
    this.handleSessionPackage(getName(MessageEnum.NavigationPayLoadMessage), message.id, message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApicallData) {
        this.handleSetCustomerDetails(responseJson.data.attributes);
      }

      this.calendarReponse(responseJson, apiRequestCallId);
      this.managePackageResponse(responseJson, apiRequestCallId, message)
      this.manageCompletePurchase(responseJson, apiRequestCallId)
      this.manageAddressResponse(responseJson, apiRequestCallId);
      this.managePaymentMethod(responseJson, apiRequestCallId)
      this.manageAddAddressResponse(responseJson, apiRequestCallId)
      this.manageDeleteCardResponse(responseJson, apiRequestCallId)
      this.manageVerifyCvvResponse(responseJson, apiRequestCallId)
      this.handleGetOngoingServiceResponse(responseJson, apiRequestCallId)
      this.handleGetCompletedServiceResponse(responseJson, apiRequestCallId)
      this.handleAllowAccessResponse(apiRequestCallId);
      this.handleReviewModalResponse(apiRequestCallId);

      this.handleServiceStatusIdResponse(responseJson, apiRequestCallId)
      this.handleCountryResponse(responseJson, apiRequestCallId);
      this.handleGetCurrentPlan(responseJson, apiRequestCallId)
      this.handleVideoCallResponce(responseJson, apiRequestCallId)
      this.handleProfileInfo(responseJson, apiRequestCallId)
      this.handleAllApi(responseJson, apiRequestCallId)




      if (responseJson && apiRequestCallId === this.postRescheduleCalenderId) {
        if (responseJson.message === 'Appointment reschedule successfully') {
          this.modifyLocalStorage()
          
          this.redirectToSomeRoute('RescheduledSuccess');
        }
      }


      if (apiRequestCallId === this.getPlannedEventListCallId) {
        this.handleSetPlannedEvents(responseJson.dashboard_planners.data);
      }

      if (apiRequestCallId === this.deletePlannerId) {
        this.onDeletePopup()
        this.loadPlannedEventList()
      }

      if (apiRequestCallId === this.getAllReviewsCallId) {
        this.handleAllReviewList(responseJson.reviews.data)
      }
    }
  }

  handleAllApi=(responseJson: ConversationMessage, apiRequestCallId:string)=>{
    if (this.getConversationApiId !== null && this.getConversationApiId === apiRequestCallId) {
      if(responseJson){
          this.handleViewChat(responseJson)
      }
    }
  }

  handleServiceStatusIdResponse = (responseJson: AllResponseData, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getServiceStatusId) {
      this.setState({
        isServiceSelected: responseJson.services_selected
      })
    }
  }

  handleCountryResponse = (responseJson: AllResponseData[], apiRequestCallId: string) => {
    if (
      responseJson &&
      apiRequestCallId === this.getCountryCallId
    ) {
      if (responseJson.length > 1) {
        const countries = responseJson.map((value: any) => (
          { value: value.attributes.id, label: value.attributes.name }
        ));
        this.setState((prevState) => ({
          countries: [...prevState.countries, ...responseJson],
          allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson],
          countryName: [...prevState.countryName, ...countries]

        }), () => {
          this.setState({ apiCallingStage: true })
        });
        this.callCountry()
      }

    }
  }



  redirectToSomeRoute = (navRoute: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), navRoute);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(message)
  }

  setTime = (timeD: string) => {
    this.setState({ timeD: timeD })
  }

  // web events
  handleSelectedDay = (event: any) => {
    const selectedDay = `${event.day}/${event.month}/${event.year}`
    this.setState({ selectedDay: selectedDay , calenderSelectedDay: event, timeSlotDate:{time:"", date:selectedDay}});
    this.getCalenderDate(selectedDay)
  }
  handleStoreCvvInfo = (event: any) => {
    this.setState({ saveCvvInfo: event.target.value })
    this.setState({ saveCardName: event.target.value })
  }

  modifyLocalStorage = async () => {
    let pData = await storage.get("plannerIdd");
    let closeData = await storage.get("closeData");
    let slotData = await storage.get("slotData");

    if (closeData) {
      if (JSON.parse(closeData).includes(JSON.parse(pData))) {
         localStorage.setItem('closeData', JSON.parse(closeData).filter((item: string | number) => item !== JSON.parse(pData)))
         localStorage.setItem('slotData', JSON.parse(slotData).filter((item: string | number) => item !== JSON.parse(pData)))

      }
    }
  }

  handleIsProcessPaymentVisible = () => {
    const { saveCardForFuture, paymentMethod, paymentMethodAddDetails } = this.state


    if (paymentMethod === 'credit_debit_card' && saveCardForFuture) {
      return true;
    } else if (paymentMethod === "apple_pay") {

      return true;
    } else if (paymentMethod !== 'credit_debit_card' && this.state.saveCvvInfo.length === 3) {
      return true;
    }
    else if (
      paymentMethodAddDetails.cardName &&
      paymentMethodAddDetails.cardNumber &&
      paymentMethodAddDetails.expiry &&
      paymentMethodAddDetails.cvv.length == 3
    ) {
      return true
    } else {

      return false;

    }
  }

  getCurrentLocation = (getNavigator?: {
    geolocation: { getCurrentPosition: Function };
  }) => {
    const locationNavigator = getNavigator || navigator;
    locationNavigator.geolocation.getCurrentPosition(
      (position: { coords: {} }) => {
        this.setCurrentLocation(position.coords);
      }
    );
  };

  handleGetDestinationList = (
    data: { [k: string]: string },
    mockGeocodeByAddress?: Function
  ) => {
    return null
  };

  onDelete = () => {
    this.setState({
      openDeleteModel: false,
      anchorEl: null
    })
  }

  deleteWealthPlannerService =()=>{
    this.setState({
       openDeleteModel: !this.state.openDeleteModel,
       anchorEl: null
       })
  }
  onDeletePopup = () => {
    this.setState({ deleteSuccessPopup: !this.state.deleteSuccessPopup })
  }

  gotoChat = (plannerId: string | number, plannerName: string, plannerImg: string) => {
    this.setState({
      chatPlanner: {
        plannerId,
        plannerImg,
        plannerName
      }
    }, () => {
      this.getConversationChat()
    })
  }

  handleViewChat = async (chatApi: ConversationMessage) => {
    let chatData = {
      ...chatApi,
      plannerID: this.state.chatPlanner.plannerId,
      userName: this.state.chatPlanner.plannerName,
      userImage: this.state.chatPlanner.plannerImg
    }
    await storage.set('CHAT_DATA', JSON.stringify(chatData));
    const chatMessage: Message = new Message(
      getName(MessageEnum.NavigattionToChatScreen)
    );
    chatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    chatMessage.addData(getName(MessageEnum.NavigationMessageSendData), chatData);
    this.send(chatMessage);
  }

  apiCallFunction = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "" } = data;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getConversationChat = async () => {
    this.getConversationApiId = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: `twilio_chat/create_conversation?type=user&receiver_id=${this.state.chatPlanner.plannerId}`
    });
  };

dialogModel = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePlannerId = requestMessage.messageId;
    const headers = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/user_service_selections/remove_planner_from_user_index?planner_id=${this.state.selectedPlannedEvent?.attributes.planner_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ openDeleteModel: !this.state.openDeleteModel })
  }
  dialogPaperStyle = {
    width: 'max-content',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '0rem 2rem',
  };
  dialogCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    minWidth: "40%",
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };
  setCurrentLocation = (data: { [keyVal: string]: string | number }) => {
    this.setState({
      currentLocation: {
        label: "Current Location",
        value: "Curren Location",
        current_latitude: String(data.latitude),
        current_longitude: String(data.longitude),
      },
    });
  };

  handleSelectDestination = (
    element: {
      destination_latitude: string;
      destination_longitude: string;
    } | null
  ) => {
    this.setState(
      {
        destination: element,
      },
      () => {
        return;
      }
    );
  };

  handleSetCustomerDetails = (data: { [keyVal: string]: string | number }) => {
    this.setState({
      cutomerData: data,
    });
    window.location.replace(
      `/GeoLocation?pickup_latitude=${data.pickup_latitude}&pickup_longitude=${data.pickup_longitude}&destination_latitude=${data.destination_latitude}&destination_longitude=${data.destination_longitude}`
    );
  };

  handleSetPlannedEvents = (data: IPlannedEvent[]) => {
    this.setState({
      plannedEvents: data,
    });
  };

  async loadPlannedEventList() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlannedEventListCallId = requestMessage.messageId;

    const headers = {
      token: this.state.token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_proposal_generation/user_service_selections/dashboard_planners'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showMore = (number: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    this.setState({ show: number })
    event.stopPropagation()
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      isUp: !prevState.isUp,
    }));
  }


  handleIconClick = (event: React.MouseEvent<SVGElement>, plannedEvent: IPlannedEvent) => {
    event.stopPropagation();
    if (this.state.selectedPlannedEvent?.id === plannedEvent.id) {
      this.setState({ showName: false, selectedPlannedEvent: null })
    } else {
      this.setState({ showName: true, selectedPlannedEvent: plannedEvent })
    }

  };

  handleDeletePopupClick = (event: React.MouseEvent<HTMLElement>,plannedEvent: IPlannedEvent) => {
    this.setState({ selectedPlannedEvent: plannedEvent, anchorEl:event?.currentTarget as HTMLAnchorElement }, () => {
      this.toggleDeletePopup(plannedEvent.id)
    })
  };
  getPackageList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPackageListCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem('token')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_appointment_management/planner_appointment_plans?planner_id=${this.state.plannerId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  managePackageResponse = async (responseJson: any, apiRequestCallId: any, message: Message) => {
    if (responseJson &&
      apiRequestCallId === this.getPackageListCallId && !responseJson.errors
    ) {
      this.setState({ packageList: responseJson?.plans?.data })
    }

  }
  async checkboxHandle(value: string) {
    if (this.state.paymentMethod != value) {
      this.setState({ paymentMethod: value });
    }
  }
  editDetails = () => {
    this.setState({ addAddressFormDetails: { name: this.state.address?.attributes?.name, address: this.state.address?.attributes?.address, city: this.state?.address?.attributes?.city, zip_code: this.state.address?.attributes?.zipcode, country: this.state.address?.attributes?.country }, paymentMethod: 'edit_address' })
  }

  maskEmail(email: string) {
    const [username, domain] = email.split('@');
    const maskedUsername = '*'.repeat(username.length);
    return `${maskedUsername}@${domain}`;
  }

  paymentMethodForDynamicCard = (values: any) => {
    this.checkboxHandle(`dynamic_card_${values.id}`)
    this.setState({ selectedPaymentMethod: values, card_id: values.id })
  }
  editAddress = () => {
    let regex = new RegExp(/[^a-zA-Z\s]/g, '')
    let cityRegex = /^[a-zA-Z\s]+$/;
    let zipRegexNum = /^\d{1,9}$/;
  
    let valueUpdated = regex.test(this.state.addAddressFormDetails.name)
    let cityName = this.state.addAddressFormDetails.city;
    let zipCode = this.state.addAddressFormDetails.zip_code;
  
    if (valueUpdated === true ) {
      toast.error(this.getStringTransactions('NameValidation'))
    } else if (!cityRegex.test(cityName)) {
      toast.error(this.getStringTransactions('CityValidation'));
    } else if (!zipRegexNum.test(zipCode)) {
      toast.error(this.getStringTransactions("ZipLengthValidation"));
    } else {
      const header = {
        token: localStorage.getItem('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editAddressCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/subscriptions/edit_billing_address?name=${this.state.addAddressFormDetails.name.replace(/\s+/g, ' ').trim()}&address=${this.state.addAddressFormDetails.address.replace(/\s+/g, ' ').trim()}&city=${this.state.addAddressFormDetails.city.replace(/\s+/g, ' ').trim()}&zipcode=${this.state.addAddressFormDetails.zip_code}&country=${this.state.addAddressFormDetails.country}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }
    }
  updateAddAddressDetails = async (name: any, value: any) => {
    
    let newValue = value;
    if (name === 'name') {

    } else if (name === 'zip_code') {
    } else if(name === 'country'){
      newValue= value.label
    }
        this.setState((prevState) => ({
            addAddressFormDetails: {
                ...prevState.addAddressFormDetails,
                [name]: newValue,
            },
        }));
    
};


  setNewAddress = () => {

    let nameRegex = new RegExp(/[^a-zA-Z\s]/g, '')
    let newZipRegex=new RegExp(/\D/g);

    let newValueUpdated = nameRegex.test(this.state.addAddressFormDetails.name)
    let newZipValueUpdated = newZipRegex.test(this.state.addAddressFormDetails.zip_code)


    if (newValueUpdated === true ) {
      toast.error(this.getStringTransactions('NameValidation'))
    
    }else if(newZipValueUpdated === true ){
      toast.error(this.getStringTransactions('ZipLengthValidation'))

    }
    else{
    const editHeader = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("name", this.state.addAddressFormDetails.name.replace(/\s+/g, ' ').trim());
    formData.append("address", this.state.addAddressFormDetails.address.replace(/\s+/g, ' ').trim());
    formData.append("city", this.state.addAddressFormDetails.city.replace(/\s+/g, ' ').trim());
    formData.append("zipcode", this.state.addAddressFormDetails.zip_code);
    formData.append("country", this.state.addAddressFormDetails.country);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/billing_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(editHeader)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);}
  }
  manageAddressResponse = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getAddressCallId) {
      if (responseJson.data) {
        this.setState({ address: responseJson.data })
      }
    }
  }
  updateAddCardDetails = async (name: any, value: any) => {
    if (name === 'cardName') {
      value = value.replace(/[^a-zA-Z\s]/g, '');
  }
    if (name === "expiry" && value.length === 5) {

      value = this.isCreditCardExpired(value)


    }

    if (name === "expiry" && value.length === 2 && value[0] !== "/" && value[1] !== "/") {
      value += "/";
    }
    if (name === "cvv" && value.length > 3) {
      return;
    }
    if (name === "expiry" && value.length > 5) {
      return;
    }
    if (name === "cardNumber" && value.length > 19) {
      return;
    }

    
    if (name === "cardNumber") {
      value = value.replace(/\s/g, '');
      value = value.replace(/(.{4})/g, '$1 ').trim();
    }

    this.setState((prevState) => ({
      paymentMethodAddDetails: {
        ...prevState.paymentMethodAddDetails,
        [name]: value,
      },
    }));
  };

  isCreditCardExpired = (expiryDate: any) => {
    const parts = expiryDate.split('/');
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[1], 10);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;


    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return `${currentMonth.toString().padStart(2, '0')}/${currentYear}`;
    } else {
      return expiryDate;
    }
  }
  manageCompletePurchase = (responseJson: any, apiRequestCallId: any) => {
    if (responseJson && apiRequestCallId == this.addPaymentMethodCallId) {
      this.setState({isLoader: false})
      if (responseJson.errors) {
        this.completePurchaseMainAPI();

        const errorMessages = Object.values(responseJson.errors).flat();
        const errorMessage = errorMessages.join('');
        toast.error(`This is ${errorMessage}`);

      }
      else {
        this.setState({ card_id: responseJson.data.id })
        this.completePurchaseMainAPI()
      }
    }
    if (responseJson && apiRequestCallId == this.addPaymentMethodFromEditCallId) {
      if (responseJson.errors) {
        toast.error("responseJson.errors")
      }
      else {
        toast.success(this.getStringTransactions('CardAddSuccess'))
        this.setState({ paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' } })
        this.getCards();
      }
    }
    this.manageCompletePurchase2(responseJson, apiRequestCallId)
  }
  manageBtn = () => {

    this.setState({ deleteAlertMessage: !this.state.deleteAlertMessage, manageBtn: !this.state.manageBtn, deleteErrorMsg: false })

    if (this.state.paymentMethod == 'credit_debit_card' && this.state.manageBtn) {
      const header = {
        token: localStorage.getItem('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodFromEditCallId = requestMessage.messageId;
      const formData = new FormData();
      let newValue = this.state.paymentMethodAddDetails.cardNumber.replace(/\s/g, '')
      formData.append("card_name", this.state.paymentMethodAddDetails.cardName);
      formData.append("card_number", newValue);
      formData.append("cvv", this.state.paymentMethodAddDetails.cvv);
      formData.append("expires_on", this.state.paymentMethodAddDetails.expiry);

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleCardToken = (cardToken: any) => {

    this.setState({isLoader: true})     
    const header = {
      token: localStorage.getItem('token'),
      "Content-Type": 'application/json',
    };
      const requestMessageForCard = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodCallId = requestMessageForCard.messageId;

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageForCard.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(cardToken)
        );
      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
};

  completePurchase = () => {
    this.setState({isLoader: true})
    if (this.state.paymentMethod == 'credit_debit_card') {
      const header = {
        token: localStorage.getItem('token')
      };
      const requestMessageForCard = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodCallId = requestMessageForCard.messageId;
      const formData = new FormData();
      let newValue = this.state.paymentMethodAddDetails.cardNumber.replace(/\s/g, '')

      formData.append("card_name", this.state.paymentMethodAddDetails.cardName.replace(/\s+/g, ' ').trim());
      formData.append("card_number", newValue);
      formData.append("cvv", this.state.paymentMethodAddDetails.cvv);
      formData.append("expires_on", this.state.paymentMethodAddDetails.expiry);

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageForCard.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
    }
    else {
      this.completePurchaseMainAPI()
    }


  }
  updateSaveCardState = () => {

    let cardNameRegex = new RegExp(/[^a-zA-Z\s]/g, '')
    
    if (this.state.saveCardForFuture) {
      this.setState({ saveCardForFuture: false })
      toast.dismiss();
      toast.error(this.getStringTransactions('CardNotSaved'));
    }else
    if(cardNameRegex.test(this.state.paymentMethodAddDetails.cardName)){
      toast.dismiss();
      toast.error(this.getStringTransactions('CardNameNotValid'));
      return
    }
    else {
      this.setState({ saveCardForFuture: true })
      toast.dismiss();
      toast.success(this.getStringTransactions('CardSaveSuccess'));
    }
  }



  manageCompletePurchase2 = (responseJson: any, apiRequestCallId: any) => {
    if (responseJson && apiRequestCallId == this.completePurchaseCallId) {
      this.setState({ isLoader: false })

      if (responseJson.message != 'slot booked successfully' && responseJson.message != 'Your free slot is booked.' ) {
        for(let errorMsg of responseJson?.errors){
          toast.error(errorMsg);
        }    
      }
      else {
        this.props.navigation.history.push(`SuccessPayment?planner_id=${responseJson?.planner_id}`)
      };
    }
  }
  managePaymentMethod = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getCardsCallId) {
      if (responseJson.data) {
        this.setState({ paymentMethods: responseJson.data })
      }
    }
  }

  deleteCard = (value: any) => {
    if (this.state.paymentMethods.length == 1) {
      this.setState({ deleteErrorMsg: true })
      return;
    }

    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/payment_methods/${value.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  manageAddAddressResponse = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      (apiRequestCallId === this.addAddressCallId || apiRequestCallId === this.editAddressCallId)) {
      if (responseJson.data) {
        this.setState({ paymentMethod: "apple_pay" })
        this.setState({ openSuccessAddress: !this.state.openSuccessAddress })
        this.getAddress()
      }else{

        toast.error(this.getStringTransactions('addressValidation'))
      }
    

    }
  }
  dialogCashModel = () => {
    this.setState({ openSuccessAddress: !this.state.openSuccessAddress })
  }
  dialogRejectModel = () => {
    this.setState({ openRejectedPopup: !this.state.openRejectedPopup })
  }
  dialogRejectedCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#6A3131',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };
  completePurchaseMainAPI = () => {
    const header = {
      token: localStorage.getItem('token'),
      "Content-Type": "application/json"

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completePurchaseCallId = requestMessage.messageId;

    const body = {
      date: this.state.timeSlotDate.date,
      time: this.state.timeSlotDate.time,
      card_id: this.state.card_id,
      planner_id: this.state.plannerId,
      plan_id: this.state.currentPlan?.id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/appointments/slot_booking`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAddress = async () => {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/show_billing_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCards = async () => {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/payment_methods`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  manageVerifyCvvResponse = (responseJson: any, apiRequestCallId: any) => {
    if (responseJson && apiRequestCallId == this.verifyCvvCallId) {
      if (responseJson.data) {
        toast.success('primary card is updated')
        this.setState({ paymentMethod: 'apple_pay', verifyCvvValue: '' })
        this.getCards()
      }
    }
  }
  manageDeleteCardResponse = (responseJson: any, apiRequestCallId: any) => {

    if (responseJson && apiRequestCallId == this.deleteCardCallId) {
      if (responseJson.message) {
        this.getCards()
        this.setState({ openDeleteSuccess: !this.state.openDeleteSuccess, paymentMethod: 'apple_pay' })
      }
    }
  }
  calendarReponse = (responseJson: any, apiRequestCallId: any) => {
    if (apiRequestCallId === this.getCalTime && responseJson) {
      this.setState({ slots: responseJson.available_slots })
     
      this.setState({availableSlot: this.state.slots?.filter(d => d.availability && new Date(d.start_time) > new Date())})
      this.setState({unAvailableSlot: this.state.slots?.filter(d => !d.availability || new Date(d.start_time) < new Date())})

      if (responseJson.next_available_date) {
          let date = moment(responseJson.next_available_date);
          let formattedDate = date.format('ddd, D MMM');          
          this.setState({ next_date: formattedDate, next_date_slot: {
            day: Number(date.format('D')),
            month: Number(date.format('M')),
            year: Number(date.format('YYYY'))
          } })
      }
    }
  }

  handleSessionPackage = (messageKey: any, messageId: any, message: any) => {
    if (messageKey == messageId) {
      const form_data = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      this.setState({ selectedPlan: form_data.selectedPlan, timeSlotDate: form_data.timeSlotDate, selectedDay: form_data.timeSlotDate.date })

    }

  }
  verifyAPICalling = (cardData: any) => {
    if (this.state.verifyCvvValue < 3) {
      toast.error(this.getStringTransactions('EnterValidCvv'))
    }
    else {
      const header = {
        token: localStorage.getItem('token')

      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.verifyCvvCallId = requestMessage.messageId;
      const [month, year] = cardData.attributes.expires_on.split('/');
      const formattedDate = `${month}/${parseInt(year, 10) + 2000}`;
      const formData = new FormData();
      formData.append("card_name", cardData.attributes.card_name);
      formData.append("card_number", cardData.attributes.card);
      formData.append("expires_on", formattedDate);
      formData.append("cvv", this.state.verifyCvvValue);
      formData.append("primary_card", "true");
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods/${cardData.id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  cvvVerify = (value: any) => {
    if (value.length > 3) {
      return;
    }
    else {
      this.setState({ verifyCvvValue: value })
    }
  }
  deleteCardModel = () => {
    this.setState({ openDeleteSuccess: !this.state.openDeleteSuccess })
  }
  getCountriesForAddress = async () => {

    const requestMessageForCountries = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCallId = requestMessageForCountries.messageId;

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
    );
    this.setState({ countryPage: this.state.countryPage + 1 })

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
  }
  handleReviewDialog(plannedEvent: IPlannedEvent) {
    this.setState({ isAllReviewopupOpen: !this.state.isAllReviewopupOpen });
    this.setState({ selectedPlannedEvent: plannedEvent });
  }

  handleReviewDialogClose() {
    this.setState({ isAllReviewopupOpen: false });
  }


  getCalenderDate = async (date: string) => {
    let token = localStorage.getItem("token")
    const selectedPlan = await storage.get("selectedBoxData")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    if (selectedPlan) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCalenderDateEndPoint}?date=${date}&wealth_planner_id=${this.state.plannerId}&plan_id=${JSON.parse(selectedPlan).id}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCalenderDateEndPoint}?date=${date}&wealth_planner_id=${this.state.plannerId}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCalTime = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getServiceStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_proposal_generation/user_service_selections/services_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRescheduleBooking = async () => {
    let pData = await storage.get("plannerIdd");

    const header = {
      token: localStorage.getItem('token'),
      "Content-Type": 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postRescheduleCalenderId = requestMessage.messageId;

    const body = {
      date: this.state.selectedDay,
      time: this.state.timeSlotDate.time,
      planner_id: pData,
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/appointments/reschedule_booking`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  }


  toggleDeletePopup(eventId: string) {
    let plannedEvents = this.state.plannedEvents.map((item) => {
      if (item.id === eventId) {
        return {
          ...item,
          deletePopup: !item.deletePopup
        }
      }
      return item
    });
    this.setState({ plannedEvents })
  }

  goToAppointment = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Appointment');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  convertToNormalDate = () => this.state.calenderSelectedDay?.day + "/" + this.state.calenderSelectedDay?.month +"/"+ this.state.calenderSelectedDay?.year    
  goToAppointPayment = () => {

    if(!this.state.timeSlotDate.time.length ){
      toast.dismiss()
      toast.error(this.getStringTransactions('planError'))
      return
    }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AppointmentPayment");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    const dateAndTime = {'date': this.convertToNormalDate(), 'time': this.state.timeSlotDate.time   }
    localStorage.setItem('timeSlotDate', JSON.stringify(dateAndTime))
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      selectedPlan: this.state.selectedPlan,
      timeSlotDate: dateAndTime


    })

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message)
  }
  navigateToCalender = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Calender');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      selectedPlan: this.state.selectedPlan,
      timeSlotDate: this.state.timeSlotDate


    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);


  }
  navigateToCalenderPage = () => {
    if(this.state.selectedPlan ===null ){
      toast.error(this.getStringTransactions('selectPlanError'))
      return 
    }
    this.navigateToCalender()
    const authToken = localStorage.getItem("token");
    const data = {
      planner_id: this.state.plannerId,
      plan_id: this.state.selectedPlan,

    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSetPlanEndPoint

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data))
    this.postPlanApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetPlanDetails = () => {
    const authToken = localStorage.getItem("token");
    const getPlannerId = localStorage.getItem("currentPlannerId");


    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/service_planners/get_plan?planner_id=${getPlannerId}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getPlanApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubscriptionPlan = (plan: number ) => {
    return this.state.packageList[plan]?.id
  }
  getPaymentPlan = (plan: number | null): string => {
    switch (plan) {
      case 1:
        return '0';
      case 2:
        return '80';
      case 3:
        return '120';
      default:
        return 'Invalid Plan';
    }
  }
  ensureHttps(externalUrl: string) {
    if (!externalUrl.startsWith("https://")) {
      externalUrl = "https://" + externalUrl;
    }
    window.open(externalUrl, '_blank')
  }

  getAllReviewsList = async (plannedEvent: IPlannedEvent) => {
    this.setState({
      selectedPlannedEvent: plannedEvent
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllReviewsCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem('token')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_formapprovalworkflow/rating_and_reviews/all_reviews?wealth_planner_id=${plannedEvent.attributes.planner_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAllReviewList(responseJson: IAllReview[]) {
    this.setState({ isAllReviewopupOpen: true, allReviews: responseJson });
  }
  handleTimeSlots(d: any) {
    if (d.availability) {
      this.setState({ timeSlotDate: { date: this.state.selectedDay, time: d.start_time } });
    }
  }

  getTabDesign = (tabName: string) => {
    if (tabName === this.state.activeTab) {
      return { fontSize: "22px", fontWeight: 600, color: "2de2d5", fontFamily: "Poppins", marginRight: "1rem", cursor: "pointer", padding: "6px", borderBottom: "1px solid" }
    }
    return { fontSize: "22px", fontWeight: 400, color: "fff", fontFamily: "Poppins", marginRight: "1rem", cursor: "pointer", padding: "6px", }
  }

  handleTabChange(tabName: string) {
    this.setState({
      activeTab: tabName
    })
  }

  handleGetOngoingServiceResponse = (responseJson: {
    ongoing_services: ISerivce[]
  }, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getOngoingServiceCallId && responseJson.ongoing_services)
      this.setState({
        onGoingServices: responseJson.ongoing_services
      })
  }

  handleGetCompletedServiceResponse = (responseJson: {
    completed_services: ISerivce[]
  }, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getCompletedServiceCallId && responseJson.completed_services) {
      this.setState({
        completedServices: responseJson.completed_services
      })
      const inCompletedService = responseJson.completed_services.find((service) => service.access_permission_status_after_service_completed === null);
      if (inCompletedService) {
        this.setState({
          currentPlannerId: inCompletedService.wealth_planner_id,
          currentPlannerImage: inCompletedService.wealth_planner_image,
          currentPlannerName: inCompletedService.wealth_planner_name,
          canReview: !inCompletedService.review_status,
          openAccessDialog: true
        })
      }
    }

  }

  getOngoingServices = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOngoingServiceCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem('token')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/user_service_selections/ongoing_services`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getCompletedServices = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompletedServiceCallId = requestMessage.messageId;
    const header = {
      token: localStorage.getItem('token')
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/user_service_selections/completed_services`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleWriteAReview = (data: {
    currentPlannerId: number,
    currentPlannerImage: string,
    currentPlannerName: string

  }) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EstatePlannerReview");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data)
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message)

  }
  handleCancelReview = () => {
    this.setState({
      openAccessDialog: false,
      openReviewDialog: false
    })
    this.getCompletedServices();
    this.getOngoingServices()

  }
  handleAccess = (permission: string) => {
    const header = {
      token: localStorage.getItem('token'),
      "Content-Type": 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allowAccessPermissionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/allow_access_permission`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          "permission": permission,
          "wealth_planner_id": this.state.currentPlannerId,
          "access_permission_after_service": true
        })
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStringTransactions = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListAppointment[languageLogin][key]
  }


  handleAllowAccessResponse = (apiRequestId: string) => {
    if (apiRequestId === this.allowAccessPermissionId) {
      this.setState({
        openAccessDialog: false,
        openReviewDialog: this.state.canReview
      })
      if (!this.state.canReview) {
        this.getCompletedServices()
      }
    }
  }
  handleReviewRating = (rating: number | null) => {
    this.setState({
      reviewRating: rating
    })
  }

  handleReviewText = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      reviewText: event.target.value as string
    })
  }

  handleSubmitReview = () => {
    if (this.state.reviewRating && this.state.reviewText.trim().length > 0) {
      const header = {
        token: localStorage.getItem('token'),
        "Content-Type": 'application/json',
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createReviewId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_formapprovalworkflow/rating_and_reviews`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({
            "wealth_planner_id": this.state.currentPlannerId,
            "rating": this.state.reviewRating,
            "description": this.state.reviewText
          })
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleReviewModalResponse = (apiRequestId: string) => {
    if (apiRequestId === this.createReviewId) {
      this.setState({
        openReviewSuccessModal: true,
      })
    }
  }

  handleReviewModalClose = () => {
    this.setState({
      openReviewSuccessModal: false
    });
    this.goBack()
  }

  startNewService() {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "FinancialPlanner");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(message)
  }
  getScreenName=()=>{
    return this.state.fromjoinCall?'EstatePlanning':"EstatePlannerDashboard"
  }
  goBack=()=>{
    if (this.state.fromjoinCall) {
      this.navigateToBackScreen2();
    } else {
      this.goToEstatePlannerDashboard();
    }
  }
  navigateToBackScreen2=()=>{
   
    this.props.navigation.history.goBack();
    this.props.navigation.history.goBack();
    this.props.navigation.history.goBack();
  }
  goToEstatePlannerDashboard() {
    const screenName=this.getScreenName()
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "EstatePlannerDashboard");

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handleNavigationMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data1 = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({
        ...this.state,
        ...data1
      });

    }
  }

  goToServices(plannerId: number) {
    storage.set("plannerId", plannerId)

    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "EngagementServiceProposal");

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  gotoNextSlot=()=>{
    if(this.state.next_date.length ===0){
      return
    }else{
      this.handleSelectedDay(this.state.next_date_slot)
    }     
  }

  goToScheduleCall(plannerId: number, errorMessage?: string | null) {
    if (errorMessage) {
      toast.error(errorMessage);
      return; 
    }
    storage.set("currentPlannerId", plannerId)

    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "Appointment");

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  goToModifyCall(plannedEvent:any) {
    storage.set("currentPlannerId", plannedEvent?.attributes?.planner_id)
    storage.set("currentPlannerData", JSON.stringify(
      {
        "wealth_planner": {
          "data": plannedEvent
        }
      }
    ))
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationTargetMessage), "ModifyBooking");

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  handleGetCurrentPlan(responseJson: {
    plan: {
      data: IPlan
    }
  }, apiCallId: string) {
    if (apiCallId == this.getPlanApiCallId) {
      this.setState({
        currentPlan: responseJson.plan.data
      })

    }
  }
  handleVideoCallResponce=(responseJson:any,apiCallId: string)=>{
    if (apiCallId == this.MeetingApiCallid2) {

      const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "VideoCall");
  message.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { 
    meetingId:responseJson.booking.data.attributes.video_meeting_id,
    authToken:responseJson.videosdk_token,
    userName:this.state.userName,
    wealthPlanneName:this.state.wealthPlannerName,
    wealthPlannerImage: this.state.wealthPlannerImage,
    currentPlannerId:this.state.currentPlannerId,
    isCustomer:true,
    BookingTime:this.state.BookingTime,
    bookingDuration:this.state.bookingDuration
  })
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message)
    }
  }
  handleProfileInfo=(responseJson:any,apiCallId: string)=>{
    if (apiCallId == this.GetUserInfoApiCallid) {
    this.setState({userName:responseJson.data.attributes.full_name})
    }
  }
  getInitials(name:string) {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.toUpperCase();
  }
   getBackgroundColor(planNumber: number = 0): {
    bgColor:string,
    imgSrc: string
   }  {
    let bgColor = "";
    let imgSrc = ""
    switch (planNumber) {
      case 1:
        bgColor = "linear-gradient(255deg, #2AC7CB, #17767C)";
        imgSrc = groupArrow
        break;
      case 2:
        bgColor = "linear-gradient(155deg,#c629fe, #9145fa)";
        imgSrc = groupArrow11
        break;
      default:
        bgColor = "linear-gradient(155deg,#2665fe, #2383fe)";
        imgSrc = groupArrowOrange

    }
    return {bgColor,imgSrc}
  }

  currencyList = [
    {
      "currency_name": "United States dollar",
      "currency_type": "USD",
      "symbol": "$",
    },
    {
      "currency_name": "Arab Emirates Dirham",
      "currency_type": "AED",
      "symbol": "د.إ",
    },
    {
      "currency_name": "British pound sterling",
      "currency_type": "GBP",
      "symbol": "£",
    },
    {
      "currency_name": "European Euro",
      "currency_type": "EUR",
      "symbol": "€",
    },
    {
      "currency_name": "Japanese yen",
      "currency_type": "JPYN",
      "symbol": "¥",
    },
    {
      "currency_name": "Australian Dollar",
      "currency_type": "AUD",
      "symbol": "A$",
    },
    {
      "currency_name": "Chinese Yuan",
      "currency_type": "CNH",
      "symbol": "¥",
    }
  ];

  getCurrencySymbol(currencyType:string) {
    for (let currency of this.currencyList) {
      if (currency.currency_type === currencyType) {
        return currency.symbol;
      }
    }
    return ''; 
  }
}






export interface IPlan {
  "id": number,
  "type": string,
  "attributes": {
    "name": string,
    "duration": number,
    "amount": number,
    "currency": string,
     tier: number
    "benefits": string[]
  }
}
export interface IPlannedEvent {
  "id": string,
  "type": string,
  deletePopup?: boolean,
  iscallenable?: boolean,
  istimeexceeded?:boolean,
  toastOpenn:boolean,
  "attributes": {
    "planner_id": number,
    "name": string,
    "location": "indore",
    "jurisdiction_advice": "yes it is",
    "disclosure": "no",
    "firm": "witmates",
    "employment_history": "1",
    "qualification": "mca",
    "experience": number,
    "certificate": string,
    "email": string,
    "terms_and_condition": boolean,
    "url": string,
    "dob": string,
    "mobile": string,
    "focus": {
      "name": string
    }[],
    "image": string,
    "request_status": string,
    "service_completed_status": string,
    "new_services_added": boolean,
    "service_added": boolean,
    "total_reviews": number,
    "average_rating": null | number,
    "rating_and_reviews": IReview[],
    "plan": Plan,
    "slot": string |null,
    "slot_time":string,
    "slot_cancellation": null,
    "pop_up_displayed": boolean,
    "allow_access": null,
    review_status: boolean,
    message?: string | null,
  }

}



export interface IReview {
  date: string,
  description: string,
  rating: number,
  user_name: string,
  user_photo: string,
}

export interface IAllReview {
  "id": string,
  "type": string,
  attributes: IReview
}

interface Plan {
  duration: number;
}
export interface ConsultationSlot {
  id: string;
  type: string;
  attributes: ConsultationSlotAttributes;
}

export interface ConsultationSlotAttributes {
  id: number;
  time_slot: string;
  availablility: boolean;
}

export interface ISerivce {
  "service_id": number,
  "name": string,
  "image": string,
  "plan_inclusions": {
    "id": 3,
    "name": string,
    "date": string,
    "status": boolean,
  }[],
  "wealth_planner_id": number,
  "wealth_planner_name": string,
  "wealth_planner_image": string,
  "access_permission": boolean,
  "access_permission_status_after_service_completed": boolean,
  "review_status": boolean
}
// Customizable Area End
