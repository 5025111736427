import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { FormControl, Typography } from '@material-ui/core';

// Define the types for a bank and its accounts
interface BankAccount {
  id: number;
  bank_account_id: number;
  account_number: string;
  image: string;
  type: string;
}

interface Bank {
  name: string;
  bank_id: number;
  accounts: BankAccount[];
}

// Define the props for the component
interface MultipleSelectInputFiledProps {
  banks: Bank[]; // List of banks with accounts
  selectedBanks: number[]; // Selected bank account IDs
  setFieldValue: (field: string, value: any) => void; // setFieldValue from Formik
}

// Define language strings for Arabic and English
const languageStrings = {
  Arabic: {
    selectBanks: "اختر البنوك",
    noBanksAvailable: "لا توجد بنوك متاحة",
  },
  English: {
    selectBanks: "Select Banks",
    noBanksAvailable: "No Banks Available",
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    border: '1px solid #2CE2D5 !important',
    padding: '12px',
    borderRadius: '10px',
  },
  select: {
    width: '100% !important',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    color: '#FFFFFF !important',
  },
  menuPaper: {
    color: '#FFFFFF',
    backgroundColor: '#395D6B',
  },
  checkbox: {
    color: '#FFFFFF',
    '&.Mui-checked': {
      color: '#FFFFFF',
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MultipleSelectInputFiled: React.FC<MultipleSelectInputFiledProps> = ({
  banks,
  selectedBanks,
  setFieldValue,
}) => {
  const classes = useStyles();
  const [language, setLanguage] = useState<'Arabic' | 'English'>('Arabic'); // Default language is Arabic

  // Effect to read language preference from localStorage
  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'English' || selectedLanguage === 'Arabic') {
      setLanguage(selectedLanguage as 'Arabic' | 'English'); // Set language based on localStorage
    }
  }, []);

  // Handles the change for the multiple select field
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedBankIds = event.target.value as number[]; // Cast the value to number[]
    setFieldValue('selectedBanks', selectedBankIds);  // Update Formik field value
  };

  // Check if banks array is empty
  const isEmpty = banks.length === 0;

  // Function to get selected account numbers as a string
  const getSelectedItemsString = (): string => {
    const selectedItems = selectedBanks.map((id) => {
      const bank = banks.find((b) =>
        b.accounts.some((account) => account.bank_account_id === id)
      );
      const account = bank?.accounts.find(
        (account) => account.bank_account_id === id
      );
      return account ? account.account_number : '';
    });

    return selectedItems.filter((item) => item !== '').join(', ');
  };

  // Get translated text for the current language
  const t = (key: keyof typeof languageStrings['Arabic']) => {
    return languageStrings[language][key];
  };

  return (
    <FormControl className={classes.formControl} disabled={isEmpty}>
      {isEmpty ? (
        // Display message when no banks are available
        <Typography style={{ color: "#2CE2D5" }}>
          {t('noBanksAvailable')}
        </Typography>
      ) : (
        <Select
          title={selectedBanks.length > 0 ? getSelectedItemsString() : ''}
          multiple
          value={selectedBanks || []} // Ensure it's an array
          onChange={handleChange}
          displayEmpty // This ensures that the placeholder is shown when no value is selected
          renderValue={(selected) => {
            if (!selected || (selected as number[]).length === 0) {
              return t('selectBanks'); // Return placeholder in the selected language
            }
            return (selected as number[]).map((id) => {
              // Find the selected account
              const selectedAccount = banks
                .flatMap((bank) => bank.accounts)
                .find((account) => account.bank_account_id === id);

              if (selectedAccount) {
                const bank = banks.find((bank) =>
                  bank.accounts.some(account => account.bank_account_id === id));
                return `${bank?.name} - ${selectedAccount.account_number} (${selectedAccount.type})`;
              }
              return null;
            }).join(', ');
          }}
          style={{ width: '100%' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 300,
              },
              className: classes.menuPaper,
            },
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          classes={{ select: classes.select, icon: classes.icon }}
        >
          {banks.map((bank) =>
            bank.accounts.map((account) => (
              <MenuItem
                key={account.bank_account_id}
                value={account.bank_account_id}
                className={classes.menuItem}
              >
                <Checkbox
                  checked={selectedBanks.indexOf(account.bank_account_id) > -1}
                />
                <Avatar src={account.image} className={classes.avatar} />
                <ListItemText
                  primary={`${bank.name} - ${account.account_number} (${account.type})`}
                />
              </MenuItem>
            ))
          )}
        </Select>
      )}
    </FormControl>
  );
};

export default MultipleSelectInputFiled;
